var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-drawer',{attrs:{"visible":_vm.visible,"width":1300,"body-style":{ paddingBottom: '80px' },"title":"名单人员观看时长"},on:{"close":_vm.ClosMain}},[_c('div',{staticStyle:{"width":"98%","text-align":"right"}},[_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":_vm.exportBtn}},[_c('a-icon',{staticStyle:{"color":"#1ea134","font-size":"16px"},attrs:{"type":"file-excel"}}),_vm._v(" 批量导出 ")],1)],1),_c('a-table',{staticStyle:{"margin-top":"10px","width":"98%"},attrs:{"columns":_vm.columns_People_watch,"rowKey":function (record){ return record.id; },"pagination":false,"data-source":_vm.data_People_watch}}),_c('div',{staticStyle:{"width":"100%","height":"50px"}},[_c('div',{staticStyle:{"float":"right","margin-right":"20px","margin-top":"15px"}},[_c('a-pagination',{attrs:{"current":_vm.page_no,"total":_vm.tableCount_People_watch},on:{"change":_vm.listChange_People_watch}})],1)]),_c('div',{style:({
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        })},[_c('a-button',{style:({ marginRight: '8px' }),on:{"click":_vm.ClosMain}},[_vm._v(" 取消 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }