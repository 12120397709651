// 医院管理api
import {get,post,put,del} from "./base";
import {llget,drugPut,drugPost,drugDel,postUserId,putDragById,delUserId,drugGet,patch,getDrugExel} from "./base";
// 修改科室
export function putHospitalDepartments(data){
    return put('/v3/hospitalDepartments',data)
}
// 根据医院id获取科室
export function getHospitalDepartments(hospitalId) {
    return get(`/v3/hospitalDepartments/${hospitalId}`);
}
// 添加科室  
export function postHospitalDepartments(data){
    return post('/v3/hospitalDepartments',data)
}
// 获取医院列表
export function getHospital(data){
    return get('/v3/hospitals',data)
}
export function getHospitalId(id){
    return get('/v3/hospital/'+id)
}
// 添加医院
export function postHospitalAdd(data){
    return post('/v3/hospital',data)
}
// 修改医院信息
// export function putHospitalEdit(id,data){   /hospital/{id}
//     return drugPut('/v3/hospital/'+id,data)
// }
export function putHospitalEdit(id,data) {
    return put(`/v3/hospital/${id}`,data);
}
// 删除医院
export function delHospital(id){
    return del('/v3/hospital/'+id)
}
// 删除科室信息  /v3/hospitalDepartments/{id}
export function delhospitalDepartments(id) {
    return del(`/v3/hospitalDepartments/${id}`);
}