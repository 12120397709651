<template>
  <div class="setLive" style=" margin: -15px -15px;">
    <a-spin :spinning="spinning">
    <div class="setLive-title">
      <div class="setLive-title-title">{{titleList.title}}</div>
    </div>
    <div class="setLive-top">
      <div class="setLive-top-content" style="margin-left: 50px">
        <div style="width: 50px">标题:</div>
        <div style="width: 80%">
          <a-input
              v-model="searchForm.title"
              style="width: 200px"
              placeholder="请输入"/>
        </div>
      </div>
      <div class="setLive-top-content" >
        <div style="width: 70px">直播日期:</div>
        <div style="width: 80%">
          <a-date-picker
              placeholder="请选择"
              :allowClear="false"
              v-model="searchForm.time"
              @change="onChange" />
        </div>
      </div>
      <div class="setLive-top-content">
        <div>
          <a-button type="primary" @click="seachBtn">查询</a-button>
          <a-button @click="restCloseBtn" style="margin-left: 15px">重置</a-button>
        </div>
      </div>
      <div class="setLive-top-content">
        <div class="setLive-title-address">
          <div>
            <a-select default-value="会议地址" style="width: 220px" @change="handleMenuClick">
              <a-select-option value="1">
                会议地址
              </a-select-option>
              <a-select-option value="2">
                免登录（游客）地址
              </a-select-option>
            </a-select>
          </div>
          <div v-if="false">
            <a-input
                id="copy"
                v-model="targetUrl_top"
                :disabled="true"
                style="width: 100%"
                placeholder="请输入" />
          </div>
          <div style="color: #1374ce;">
            <a-button @click="copy">复制</a-button>
          </div>
        </div>
      </div>
    </div>
    <div class="setLive-table">
      <div class="setLive-table-content">
        <div>
          <a-button
              @click="addBtn"
              style="width: 100px"
              type="primary">新增</a-button>
          <a-button
              v-if="this.type === 'CONVENTION'"
              @click="listPersons"
              style="margin-left: 10px">人员名单</a-button>
          <a-button
              v-if="this.type === 'CONVENTION'"
              @click="listPersonsModel"
              style="margin-left: 10px;">名单人员观看时长</a-button>
        </div>
        <div style="margin-top: 15px">
<!--          :data-source="tablelist">-->
          <a-table
              :pagination="false"
              :columns="columns"
              :rowKey='record=>record.id'
              :data-source="tablelist">
            <span slot="action" slot-scope="text, record">
              <a @click="restBtn(record)" style="margin-right: 10px">修改</a>
<!--              <a-popconfirm-->
<!--                  style="margin-left: 10px;margin-right: 10px"-->
<!--                  title="是否确认删除?"-->
<!--                  ok-text="是"-->
<!--                  cancel-text="否"-->
<!--                  @confirm="delConfirm(record.id)"-->
<!--              ><a style="margin-left: 10px">删除</a>-->
<!--              </a-popconfirm>-->
              <a-dropdown>
                <a-menu slot="overlay">
                  <a-menu-item  @click="onWayBtn(record.loginType,record.id)" key="1">
                    登陆方式
                  </a-menu-item>
                  <a-menu-item  @click="dealBtn(record.protocolIsOpen,record.id,record)" key="2">
                    协议
                  </a-menu-item>
                  <a-menu-item @click="functionBtn(record)" key="3">
                    模块功能
                  </a-menu-item>
                  <a-menu-item @click="suspensionBtn(record)" key="4">
                      悬浮功能
                  </a-menu-item>
                   <a-menu-item @click="uploadDesignSourceFile(record)" key="5">
                      上传设计源文件
                  </a-menu-item>
                   <a-menu-item @click="addExpertGroup(record)" key="6">
                      添加专家组
                  </a-menu-item>
                </a-menu>
                <a class="ant-dropdown-link" > 更多 <a-icon type="down" /></a>
              </a-dropdown>
            </span>
<!--            &lt;!&ndash;协议设置&ndash;&gt;-->
<!--            <span slot="protocolIsOpen" slot-scope="text, record">-->
<!--              <a @click="dealBtn(record.protocolIsOpen,record.id,record)"-->
<!--                 v-if="record.protocolIsOpen?'1':'0'">-->
<!--                {{ record.protocolIsOpen?'已开启':'已关闭' }}-->
<!--              </a>-->
<!--            </span>-->
            <!--回放设置-->
            <span slot="replayIsOpen" slot-scope="text, record">
              <a  @click="playbackBtn(record.replayIsOpen,record.id,record)"
                 v-if="record.replayIsOpen?'1':'0'">
                {{ record.replayIsOpen?'已开启':'未开启' }}
              </a>
            </span>
            <span slot="scoreValue" slot-scope="text, record">
              <a @click="scoreOpen(record.id)">{{record.score}}</a>
            </span>
            <!--地址-->
            <span slot="address" slot-scope="text, record">
              <a v-if="record.obsPushIp" @click="addresBtn(record)">查看地址</a>
              <a-popconfirm
                  v-else
                  title="地址生成后，推流时间不可修改，是否确认生成？"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="addressTrueBtn(record)"
              ><a style="color: red">生成推流地址</a>
              </a-popconfirm>
            </span>


            <span slot="endTime" slot-scope="text, record">
              <div>
                {{(record.startTime).slice(11,19)}}~{{(record.endTime).slice(11,19)}}
              </div>
            </span>
            <span slot="liveDate" slot-scope="text, record">
              <div>
                {{record.liveDate}}
              </div>
            </span>

<!--            &lt;!&ndash;登陆方式 &ndash;&gt;-->
<!--            <span slot="loginType" slot-scope="text, record">-->
<!--&lt;!&ndash;              登录类型phone_code:手机号验证码   name_hospital:姓名医院&ndash;&gt;-->
<!--              <a @click="onWayBtn(record.loginType,record.id)" v-if="record.loginType === 'phone_code'">-->
<!--                手机号验证码-->
<!--              </a>-->
<!--              <a @click="onWayBtn(record.loginType,record.id)" v-if="record.loginType === 'name_hospital'">-->
<!--                医院姓名-->
<!--              </a>-->
<!--              <a @click="onWayBtn(record.loginType,record.id)" v-if="record.loginType === 'none_login'">-->
<!--                无登录-->
<!--              </a>-->
<!--            </span>-->
<!--            &lt;!&ndash;回放&ndash;&gt;-->
<!--            <span slot="replayIsOpen" slot-scope="text, record">-->
<!--              <a-->
<!--                  @click="playbackBtn(record.replayIsOpen,record.id,record)"-->
<!--                  v-if="record.replayIsOpen?'1':'0'">-->
<!--                {{ record.replayIsOpen?'已开启':'已关闭' }}-->
<!--              </a>-->
<!--            </span>-->
<!--             &lt;!&ndash; 企业鸣谢&ndash;&gt;-->
<!--            <span slot="EnterpriseCredit" slot-scope="text, record">-->
<!--              <a @click="EnterpriseBtn(record.isEnterprise,record.id,record)"-->
<!--                 v-if="record.isEnterprise?'1':'0'">-->
<!--                {{ record.isEnterprise?'已开启':'已关闭' }}-->
<!--              </a>-->
<!--            </span>-->
            <!--是否开启-->
            <span slot="isOpen" slot-scope="text, record">
              <a-switch
                  :checked="record.isOpen ===1 ? true:false"
                  @click="tableIsOpen(record.isOpen === 1? 0 : 1,record.id)"
                  checked-children="开"
                  un-checked-children="关"/>
            </span>

<!--            <span slot="customTitle">是否开启 <a-icon type="info-circle" /></span>-->
            <span slot="customTitle">
              是否开启
              <a-tooltip placement="top">
                <template slot="title">
                  <span>控制系列会的目录列表直播间是否显示，主要针对实际已结束，但是推流时间没结束的直播间</span>
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>

            </span>
          </a-table>
        </div>
      </div>
      <div style="width: 100%;height: 50px;">
        <div style="float: right;margin-right: 20px;margin-top: 15px">
          <a-pagination
              :current="page_no"
              @change="listChange"
              :total="tableCount" />
        </div>
      </div>
    </div>
<!----------------------------------------对话框------------------>
     <!--    新增 -- 修改-->
    <a-drawer
        :get-container="false"
        :width="700"
        :visible="visible"
        :title="visibleTitle"
        :body-style="{ paddingBottom: '80px' }"
        @close="addClose">
      <a-form-model
          ref="ruleFormAdd"
          :model="form"
          :colon="false"
          :rules="rules"
          :label-col="labelCol_add"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item prop="live_date">
          <template slot="label">直播日期 :</template>
          <a-date-picker
              :disabled="obsPushIp ? true:false"
              placeholder="请选择"
              v-model="form.live_date"
              style="width: 370px"
              @change="dateChange" />
        </a-form-model-item>
        <a-form-model-item prop="live_start_time">
          <template slot="label">
              <span>直播开始时间
              <a-tooltip placement="top">
                <template slot="title">
                  <span>直播实际开始时间，用于直播间倒计时</span>
                </template>
                <a-icon type="info-circle" />
              </a-tooltip> :</span>
          </template>

          <div style="display: flex">
            <a-time-picker
                :allowClear="false"
                style="width: 350px;margin-left: 3px"
                :value="moment(form.live_start_time, 'HH:mm:ss')"
                :default-open-value="moment('00:00:00', 'HH:mm:ss')"
                @change="selectTime">
            </a-time-picker>
            <a @click="openSample('setLiveMeet_liveStartTime')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
          </div>
        </a-form-model-item>
        <a-form-model-item prop="start_time">
          <template slot="label">推流开始时间 :</template>
          <a-time-picker
              :disabled="obsPushIp ? true:false"
              :allowClear="false"
              style="width: 370px"
              :value="moment(form.start_time, 'HH:mm:ss')"
              :default-open-value="moment('00:00:00', 'HH:mm:ss')"
              @change="selectTime1">
          </a-time-picker>
        </a-form-model-item>
        <a-form-model-item prop="end_time">
          <template slot="label">推流结束时间 :</template>
          <a-time-picker
              :disabledHours="disabledStartHours"
              :disabledMinutes="disabledStar"
              :disabledSeconds="disabledStartSeconds"
              :disabled="obsPushIp ? true:false"
              :allowClear="false"
              style="width: 370px"
              :value="moment(form.end_time, 'HH:mm:ss')"
              :default-open-value="moment('00:00:00', 'HH:mm:ss')"
              @change="selectTime2">
          </a-time-picker>
        </a-form-model-item>
        <a-form-model-item prop="title">
          <template slot="label">标题 :</template>

          <div style="display: flex">
            <a-textarea
                v-model="form.title"
                placeholder="请输入"
                style="width: 350px"
                :auto-size="{ minRows: 2, maxRows: 3 }"
            />
            <a @click="openSample('setLiveMeet_title')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
          </div>
        </a-form-model-item>
        <a-form-model-item prop="show_time">
          <template slot="label">显示时间 :</template>
          <div style="display: flex">
            <a-input
                style="width: 350px"
                v-model="form.show_time"
                placeholder="请输入"/>
            <a @click="openSample('setLiveMeet_showTime')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
          </div>
        </a-form-model-item>
        <a-form-model-item prop="leader_operate">
          <template slot="label">直播负责人 :</template>
          <a-select
              show-search
              option-filter-prop="children"
              v-model="form.leader_operate"
              placeholder="请选择">
            <a-select-option
                v-for="select_o in leaderOperateList"
                :value="select_o">
              {{select_o}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="leader_sales">
<!--          <template slot="label">销售部负责人 :</template>-->
          <template slot="label">业务负责人 :</template>
          <a-select
              show-search
              option-filter-prop="children"
              v-model="form.leader_sales"
              placeholder="请选择">
            <a-select-option
                v-for="select_o in PerList"
                :value="select_o.userName">
              {{select_o.userName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="live_broadcastType">
          <!--          <template slot="label">销售部负责人 :</template>-->
          <template slot="label">直播类型 :</template>
          <a-select
              v-model="form.live_broadcastType"
              placeholder="请选择">
            <a-select-option
                v-for="selectLive in liveBroadcastType"
                :value="selectLive.id">
              {{selectLive.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="Live_broadcast">
          <template slot="label">直播场次 :</template>
          <a-radio-group v-model="form.Live_broadcast">
            <a-radio :value="1">
              一场
            </a-radio>
            <a-radio :value="2">
              二场
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item>
          <template slot="label">
              <span>开放范围
              <a-tooltip placement="top">
                <template slot="title">
                  <span>当直播间是需要导入名单才能观看，可打开此开关</span>
                </template>
                <a-icon type="info-circle" />
              </a-tooltip> :</span>
          </template>
          <a-switch
              v-model="form.isOpenScope === 1 ? true:false"
              checked-children="开"
              un-checked-children="关"
              @click="model_change_isOpen(form.isOpenScope === 1 ?0:1)"
              default-checked />
        </a-form-model-item>
        <a-form-model-item prop="cover_url">
          <template slot="label">封面图 :</template>
          <div style="display: flex;width: 500px">
            <div style="display: flex;width: 400px;">
              <div class="uploadImg" >
                <input
                    style="width: 100px;height: 100px;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="upDetailsCover"
                    id="deyalisId"
                    ref="deyails" />
              </div>
              <div
                  class="upload_deyails">
                <img
                    v-if="form.cover_url"
                    alt=""
                    style="width: 100%;height:100%;"
                    :src="form.cover_url"
                />
              </div>
            </div>
            <div style="margin-left: 10px">
              <a @click="openSample('setLiveMeet_cover_url')" style="width: 50px;margin-top: -5px;margin-left: -20px">示例图</a>
            </div>
          </div>
          <div style="color: #a1a1a1;width: 500px">注:建议尺寸1920px*1080px，宽度不能超过1920px，大小不超过2M</div>
        </a-form-model-item>
        <a-form-model-item prop="desc_image_url">
          <template slot="label">详情海报 :</template>

          <div style="display: flex;width: 500px">
            <div style="display: flex;width: 300px;">
              <div class="uploadImg" >
                <input
                    style="width: 100px;height: 100px;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="upPosterCover"
                    id="descImageUrlId"
                    ref="descImageUrl" />
              </div>
              <div
                  class="upload_posterH">
                <img
                    v-if="form.desc_image_url"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="form.desc_image_url"
                />
              </div>
            </div>
            <div style="margin-left: 10px">
              <a @click="openSample('setLiveMeet_descImageUrl')" style="width: 50px;margin-top: -5px;margin-left: -45px">示例图</a>
            </div>
          </div>
          <div style="color: #a1a1a1">注:宽度不能超过1920，大小不超过3M</div>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          取消
        </a-button>
        <a-button type="primary" @click="onSubmit">
          确定
        </a-button>
      </div>
    </a-drawer>
     <!--   上传设计源文件   -->
      <a-drawer
          :get-container="false"
          :width="600"
          :visible="designSourceFileVisible"
          title="上传设计源文件"
          :body-style="{ paddingBottom: '80px' }"
          @close="designSourceFileClose">
        <a-spin :spinning="fileSpin">
        <a-form-model
            ref="ruleForm"
            :model="designSourceFileForm"
            :label-col="labelCol_add"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item prop="uploadType" label="上传类型">
            <a-checkbox-group v-model="designSourceFileForm.uploadType" @change="uploadTypeChange" style="width: 400px;margin-top: 10px">
              <a-row>
                <a-col :span="6" v-for="(cols,index) in uploadTypeList" :key="index">
                  <a-checkbox :value="cols.id">
                    {{cols.name}}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-form-model-item>
          <a-form-model-item v-for="(files,indexs) in designSourceFileForm.fileList" :key="indexs">
            <template slot="label">
              {{ returnName(files.type) }}设计源文件
            </template>
            <div style="width: 500px">
              <a-switch
                  v-model="files.switch"
                  checked-children="开"
                  un-checked-children="关"
                  @click="designSourceFileForm_isOpen(files.switch ? false:true,files)"
                  default-checked />
              <span style="margin-left: 10px;color:#a1a1a1 ">是否发布到模板库</span>
              <div style="display: flex;">
                <div style="position: relative;width: 200px">
                  <a-button><a-icon type="upload" /> 上传文件</a-button>
                  <input
                      style="width: 110px;height: 35px;opacity: 0;position: absolute;left: 0;top: 0;"
                      accept=".psd"
                      type="file"
                      @change="sourceFileUp(files,files.type,indexs)"
                      :id="files.type"
                      ref="filesRef" />
                  <div style="color: #a1a1a1;">注:支持扩展名 .psd</div>
                  <div style="color: #a1a1a1;" v-if="files.imgName">{{ files.imgName }}
                    <span style="margin-left: 10px;color: red">
                      <a-icon @click="delPSD(files)" type="close-circle" />
                    </span>
                  </div>
                  <div v-if="files.imgUrlPSD">
                    <a-progress size="small" :percent="files.imgUrlPSD ? 100 : 0" />
                  </div>
                </div>
                <div style="position: relative; width: 160px;height: 120px;">
                  <div class="upload_poster" style="position: absolute;top: 0;">
<!--                    上传预览图-->
                    <img
                        v-if="files.imgUrl"
                        alt=""
                        style="width: 100%;height:100%;background-color: #fff"
                        :src="files.imgUrl"/>
                  </div>
<!--                  <a-icon @click="delImg(files)" v-if="files.imgUrl" style="position: absolute;top: 10px;color: red;z-index: 99999;text-align: right;width: 170px" type="close-circle"/>-->
                  <input
                      v-show="returnName(files.type) !== 'KV图' && returnName(files.type) !== '海报'"
                      style="width: 100%;height: 100%;opacity: 0;position: absolute;top: 0;border: 1px solid #000;left: 20px"
                      accept="image/*"
                      type="file"
                      @change="sourceFileImgUp(files,files.type,indexs)"
                      :id="'img'+files.type"
                      ref="filesRefImg" />
                </div>
                </div>
            </div>
          </a-form-model-item>
        </a-form-model>
        </a-spin>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="designSourceFileClose">
            取消
          </a-button>
          <a-button type="primary" @click="designSourceFileSubmit">
            确定
          </a-button>
        </div>
      </a-drawer>
    <!-- 人员名单-->
      <a-drawer
          :width="1200"
          :visible="ExcelMember"
          title="人员名单"
          :body-style="{ paddingBottom: '80px' }"
          @close="excelClose">
        <div style="width: 100%">
          <div style="display: flex;justify-content: center;height: 35px;line-height: 35px">
            <span>选择文件 </span>
            <a-input-search
                  v-model="changeFile"
                  style="width: 260px;margin-left: 5px"
                  placeholder="请上传.xlsx文件">
                <a-button slot="enterButton" style="width: 80px">
                  <div class="add-file">
<!--                    <div style="margin-top: 5px;margin-left: -25px"  @click="toImport">选择</div>-->
                    <div style="margin-top: 5px;margin-left: -25px">选择</div>
                    <input
                        id="uploadFile"
                        type="file"
                        @change="changeFileElsx"
                        ref="uploadFile_inputFile"
                        class="fileAdd"
                        style="position: absolute;top: 1px;left: -12px;width: 80px;opacity:0"
                        accept=".xlsx,.xls"
                    />
                  </div>
                </a-button>
              </a-input-search>
            <a-button
                  @click="importAddFile"
                  style="margin-left: 10px"
                  type="primary">确认导入</a-button>
            <a-button
                @click="DownloadTemplate"
                style="margin-left: 10px"
            >下载模板</a-button>
          </div>
          <div style="display: flex;height: 35px;line-height: 35px;margin-top: 20px;justify-content: space-between">
            <div>
              <span>手机号：</span>
              <a-input allowClear
                       placeholder="请输入"
                       style="width: 200px;"
                       @pressEnter="inquireBtn"
                       v-model="listTel"/>
              <a-button
                  @click="inquireBtn"
                  style="margin-left: 10px"
                  type="primary">查询</a-button>
            </div>
            <a-button style="margin-top: 5px" type="primary"  @click="addPerson">新增</a-button>
          </div>
          <div style="margin-top: 20px">
            <a-table :columns="columnsList"
                     :pagination="false"
                     :rowKey='record=>record.id'
                     :data-source="dataLists">
              <template slot="action" slot-scope="text, record">
                <a-popconfirm
                    title="是否确认删除?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="confirmDelList(record.id)"
                ><a>删除</a>
                </a-popconfirm>
              </template>
              <template slot="serialNumber" slot-scope="text, record,index">
                <span>{{(listPageNo-1)*10+index+1}}</span>
              </template>
              <template slot="type" slot-scope="text, record">
               <span v-if="text === '1001'">医生</span>
               <span v-if="text === '1002'">护士</span>
               <span v-if="text === '1003'">医技</span>
               <span v-if="text === '1004'">药师</span>
               <span v-if="text === '1005'">学生</span>
               <span v-if="text === '1006'">其他医务从业者</span>
               <span v-if="text === '1007'">非医务从业者</span>
              </template>
            </a-table>
            <div style="width: 100%;display: flex;justify-content: right;margin-top: 5px">
              <a-pagination
                  show-quick-jumper
                  show-size-changer
                  :current="listPageNo"
                  :pageSize="listpageSize"
                  :total="listCount"
                  @showSizeChange="templateChangeSize"
                  @change="templateChange" />
            </div>
          </div>
        </div>
      </a-drawer>
      <!-- 人员名单新增-->
      <a-drawer
          :width="500"
          :visible="addPersonVisile"
          title="人员名单新增"
          :body-style="{ paddingBottom: '80px' }"
          @close="addPersonClose">
        <a-form-model
            ref="PersonnelListRef"
            :model="personnelListForm"
            :rules="personnelListRules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="手机号" prop="tel">
            <a-input-search
                v-model="personnelListForm.tel"
                style="width: 350px"
                placeholder="请输入"
                enter-button="搜索"
                size="large"
                @search="onSearch"
            />
          </a-form-model-item>
          <a-form-model-item label="身份">
            <a-select
                :disabled="addPersonDisabled"
                show-search
                option-filter-prop="children"
                @change="selectNumberType"
                placeholder="请选择"
                v-model="personnelListForm.type"
                style="width: 300px">
              <a-select-option value="1001">
                医生
              </a-select-option>
              <a-select-option value="1002">
                护士
              </a-select-option>
              <a-select-option value="1003">
                医技
              </a-select-option>
              <a-select-option value="1004">
                药师
              </a-select-option>
              <a-select-option value="1005">
                学生
              </a-select-option>
              <a-select-option value="1006">
                其他医务从业者
              </a-select-option>
              <a-select-option value="1007">
                非医务从业者
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="姓名" prop="name">
            <a-input id="name" @change="nameChange" :disabled="addPersonDisabled" style="width: 300px;" v-model="personnelListForm.name" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="省份/城市">
            <a-cascader
                :disabled="addPersonDisabled"
                style="width: 300px"
                v-model="personnelListForm.province"
                :options="provincePtions"
                placeholder="请选择"
                @change="provinceChange"
            />
          </a-form-model-item>
          <!-------------------------------1001 ~ 1004---------------------------------->
          <div v-if="personnelListForm.type === '1001' || personnelListForm.type === '1002' || personnelListForm.type === '1003' ||personnelListForm.type === '1004'">
          <a-form-model-item label="医院">
            <a-select
                show-search
                option-filter-prop="children"
                @popupScroll="popupScrollHos"
                @search="selectHos"
                :disabled="addPersonDisabled"
                style="width: 300px"
                v-model="personnelListForm.hospitalName"
                placeholder="请选择">
              <a-select-option
                  v-for="item in hospitalNameList"
                  :value="item.name">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="科室">
            <a-cascader
                :disabled="addPersonDisabled"
                style="width: 300px"
                v-model="personnelListForm.hospitalOffices"
                :options="hospitalOfficesList"
                placeholder="请选择"/>
          </a-form-model-item>
          <a-form-model-item label="职称">
            <a-select
                :disabled="addPersonDisabled"
                style="width: 300px"
                v-model="personnelListForm.jobTitle"
                placeholder="请选择">
              <a-select-option
                  v-for="item in jobTitleList"
                  :value="item.jobTitle">
                {{item.jobTitle}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          </div>
          <!-------------------------------1005------------------------------------------->
          <div v-if="personnelListForm.type === '1005'">
          <a-form-model-item label="学院" >
            <a-select
                show-search
                option-filter-prop="children"
                @popupScroll="popupScrollSchool"
                @search="selectSchool"
                :disabled="addPersonDisabled"
                style="width: 300px"
                v-model="personnelListForm.school"
                placeholder="请选择">
              <a-select-option
                  v-for="item in schoolListData"
                  :value="item.name">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="学历">
            <a-select :disabled="addPersonDisabled" style="width: 300px" placeholder="请选择" v-model="personnelListForm.education">
              <a-select-option
                  v-for="item in educationList"
                  :value="item.education">
                {{item.education}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="专业">
            <a-input :disabled="addPersonDisabled" style="width: 300px" v-model="personnelListForm.specialty" placeholder="请输入" />
          </a-form-model-item>
          </div>
          <!-------------------------------1006  ~  1007------------------------------------------->
          <div v-if="personnelListForm.type === '1006' || personnelListForm.type === '1007'">
          <a-form-model-item label="单位" >
            <a-input :disabled="addPersonDisabled" style="width: 300px" v-model="personnelListForm.workUnit" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="职务" >
            <a-input :disabled="addPersonDisabled" style="width: 300px" v-model="personnelListForm.post" placeholder="请输入" />
          </a-form-model-item>
          </div>
          <!--      新增时共有的        -->
          <a-form-model-item label="性别">
            <a-select :disabled="addPersonDisabled" style="width: 300px" placeholder="请选择" v-model="personnelListForm.sex">
              <a-select-option
                  v-for="item in sexList"
                  :value="item.value">
                {{item.sex}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="邮箱">
            <a-input :disabled="addPersonDisabled" style="width: 300px" v-model="personnelListForm.email" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="生日">
            <a-date-picker :disabled="addPersonDisabled" placeholder="请选择" style="width: 300px" v-model="personnelListForm.birthday" />
          </a-form-model-item>
          <a-form-model-item label="认证状态">
<!--            <a-input :disabled="true" style="width: 300px" v-model="personnelListForm.certStatus" placeholder="请输入" />-->
            <a-select
                :disabled="true"
                style="width: 300px"
                v-model="personnelListForm.certStatus"
                placeholder="请选择">
              <a-select-option
                  v-for="item in certStatus"
                  :value="item.value">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="注册渠道">
            <a-input :disabled="true" style="width: 300px;margin-bottom: 20px" v-model="personnelListForm.registerChannel" placeholder="请输入" />
          </a-form-model-item>
        </a-form-model>
        <div style="display: flex;margin-left: 320px">
          <a-button v-show="addPersonDisabled" @click="editPerBtn" type="primary">编辑</a-button>
          <div style="display: flex" v-show="!addPersonDisabled">
            <a-button @click="()=>{this.addPersonDisabled = true}">取消</a-button>
            <a-button @click="saveEditPer" type="primary" style="margin-left: 10px">保存</a-button>
          </div>
        </div>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '1px' }" @click="addPersonClose">取消</a-button>
          <a-button type="primary" @click="addPersonOnSubmit">确认</a-button>
        </div>
      </a-drawer>
    <!--    登陆方式-->
    <a-modal
        :width="500"
        v-model="visibleOnWay"
        title="登陆方式(设置登陆方式)"
        @ok="onWaySubmit">
      <a-form-model
          :model="onWayForm"
          :label-col="labelColLogin"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="选择登陆方式">
          <a-radio-group v-model="onWayForm.onWay" @change="selectLoginChange">
          <a-radio :style="radioStyle" value="phone_code">
            手机号验证码登录
          </a-radio>
          <a-radio :style="radioStyle" value="name_hospital">
            姓名医院登录（游客模式）
          </a-radio>
<!--          <a-radio :style="radioStyle" value="none_login">-->
<!--              无登录（游客模式）-->
<!--          </a-radio>-->
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-show="typeLoginStatus" label="选择完善身份信息">
          <a-radio-group v-model="onWayForm.status" @change="selectStatusChange">
            <a-radio :style="radioStyle" :value='1'>
              是
            </a-radio>
            <a-radio :style="radioStyle" :value='0'>
              否
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
<!--        <a-form-model-item v-show="!typeLoginStatus">-->
<!--          <div style="width: 200px;margin-left: 32%">-->
<!--            <span>显示科室：</span>-->
<!--            <a-switch-->
<!--                :checked="isOpen ===1 ? true:false"-->
<!--                @click="officeIsOpen(isOpen === 1? 0 : 1)"-->
<!--                checked-children="开"-->
<!--                un-checked-children="关"  />-->
<!--          </div>-->
<!--          <div style="width: 200px;margin-left: 32%">-->
<!--            <span>显示职称：</span>-->
<!--            <a-switch-->
<!--                :checked="isOpen ===1 ? true:false"-->
<!--                @click="technicalIsOpen(isOpen === 1? 0 : 1)"-->
<!--                checked-children="开"-->
<!--                un-checked-children="关"  />-->
<!--          </div>-->
<!--          <div style="width: 200px;margin-left: 27%">-->
<!--            <span>显示手机号：</span>-->
<!--            <a-switch-->
<!--                :checked="isOpen ===1 ? true:false"-->
<!--                @click="telIsOpen(isOpen === 1? 0 : 1)"-->
<!--                checked-children="开"-->
<!--                un-checked-children="关"  />-->
<!--          </div>-->
<!--        </a-form-model-item>-->
      </a-form-model>
    </a-modal>
    <!--  警告框  -->
    <a-modal
        :visible="visibleModal"
        @ok="handleModal"
        @cancel="handleModal"
    >
      <span>直播间登录方式必须是【手机号验证码登录】，且完善身份信息为【是】，才可开启时长</span>
    </a-modal>
    <!--    时长-->
    <a-modal
        :width="500"
        v-model="visibleDuration"
        title="时长(设置时长)"
        @cancel="handleDuration"
        @ok="durationSubmit">
      <a-form-model
          :model="durationForm"
          :label-col="labelColduration"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="状态">
          <a-switch
              :checked="durationForm.duration_is_open"
              @click="switchChange(durationForm.duration_is_open ? false : true)"
              checked-children="开"
              un-checked-children="关"  />
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea
              v-model="durationForm.duration_remark"
              placeholder="请输入"
              :auto-size="{ minRows: 2, maxRows: 3 }"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!--  回放  -->
    <a-drawer
        title="回放设置"
        :width="1100"
        :visible="visiblePlayback"
        :body-style="{ paddingBottom: '80px' }"
        @close="resetFormPlayback"
    >
      <div style="width: 600px">
        <div style="display: flex">
          <div style="color: black;font-size: 16px">状态:</div>
          <div style="margin-left: 15px">
            <a-switch
                :checked="PlaybackForm.replay_is_open !==1 ? false:true"
                @click="switchPlaybackChange(PlaybackForm.replay_is_open === 1? 0 : 1)"
                checked-children="开"
                un-checked-children="关"  />
          </div>
        </div>
        <div style="margin-top: 10px">
          <a-button type="primary" @click="addVideoBtn">新增</a-button>
        </div>
        <div>
          <a-table
              :pagination="false"
              style="margin-top: 15px;width: 1050px"
              :columns="columnsPlayback"
              :data-source="PlaybackTableData"
              :rowKey='record=>record.id' >
            <template
                v-for="col in ['name']"
                :slot="col"
                slot-scope="text, record, index"
            >
              <div :key="col">

                <div v-if="record.addIsShow">
                  <a-select
                      @popupScroll="popupScroll_addVideoBtn"
                      v-if="record.editable"
                      show-search
                      placeholder="输入视频标题关键字，从视频库选择"
                      option-filter-prop="children"
                      style="width: 200px"
                      @search="select_add_search"
                  ><a-select-option
                      v-for="role in videoList"
                      @click="select_add(role.title,role.id)"
                      :value="role.id">
                    {{role.title}}
                  </a-select-option>
                  </a-select>

                  <template  v-if="!record.editable">
                    <a :disabled="editingKey !== ''" @click="() => edit(record.id)">
                      {{ record.name === null || record.name === '' || record.name === undefined ?'未设置':record.name}}
                    </a>
                  </template>
                </div>

                <div v-else>
                  <a-input
                      v-if="record.editable"
                      style="margin: -5px 0"
                      :value="text"
                      @change="e => handleChange(e.target.value, record.id, col)"
                  />
                  <template  v-if="!record.editable">
                    <a :disabled="editingKey !== ''" @click="() => edit(record.id)">
                      {{ record.name === null || record.name === '' || record.name === undefined ?'未设置':record.name}}
                    </a>
                  </template>
                </div>

              </div>
            </template>
            <template slot="taskId" slot-scope="text, record, index">
              <!--        判断taskId是否为空，空时为剪辑上传，否则为自动生成      -->
              <span v-if="!record.taskId">剪辑上传</span>
              <span v-else>自动生成</span>
            </template>

            <template slot="numberIndex" slot-scope="text, record, index">
              {{PlaybackTableData.length - index}}
            </template>

            <template slot="operation" slot-scope="text, record, index">
              <div class="editable-row-operations">
                <!--保存取消-->
                <span v-if="record.editable">
              <a @click="() => save(record.id,record)">保存</a>
              <a-popconfirm
                  ok-text="是"
                  cancel-text="否"
                  style="margin-left: 5px"
                  title="是否取消?"
                  @confirm="() => cancel(record.id,record)">
                <a>取消</a>
              </a-popconfirm>
            </span>
                <!--删除-->
                <span v-if="!record.editable">
                  <a @click="upOrDownBtn('up',record.id)">上移</a>
                  <a @click="upOrDownBtn('down',record.id)" style="margin-left: 6px;margin-right: 6px">下移</a>
              <a-popconfirm
                  title="是否确认删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="confirmDel(record.id)"
              ><a>删除</a>
              </a-popconfirm>
            </span>
              </div>
            </template>
          </a-table>
        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '1px' }" @click="resetFormPlayback">
          取消
        </a-button>
<!--        <a-button type="primary" @click="onSubmitPlayback">-->
<!--          确认-->
<!--        </a-button>-->
      </div>
    </a-drawer>
    <!--  协议设置  -->
    <a-drawer
        title="协议设置"
        :width="680"
        :visible="visibleDeal"
        :body-style="{ paddingBottom: '80px' }"
        @close="resetFormDeal"
    >
      <div style="width: 600px">
        <div style="display: flex">
          <div style="color: black;font-size: 16px">状态:</div>
          <div style="margin-left: 15px">
            <a-switch
                :checked="dealForm.protocol_is_open !==1 ? false:true"
                @click="switchDealChange(dealForm.protocol_is_open === 1? 0 : 1)"
                checked-children="开"
                un-checked-children="关"  />
          </div>
        </div>
        <div style="margin-top: 20px">
          <div ref="editor" >
          </div>
        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="resetFormDeal">
          取消
        </a-button>
        <a-button type="primary" @click="onSubmitDeal">
          确认
        </a-button>
      </div>
    </a-drawer>
    <!--  地址  -->
    <a-drawer
        title="地址"
        :width="450"
        :visible="visibleAdress"
        :body-style="{ paddingBottom: '80px' }"
        @close="resetFormAddress"
    >
      <div style="width: 450px">
        <div>
          <div style="margin-left: 15px">
            推流: 服务器
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address1"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 30px">
              <a-icon @click="copySum(addressForm.address1)" type="copy" />
            </div>
          </div>

        </div>
        <div style="margin-top: 15px">
          <div style="margin-left: 15px">
            推流: 串流密钥
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address2"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 40px">
              <a-icon @click="copySum(addressForm.address2)" type="copy" />
            </div>
          </div>
        </div>
        <div style="margin-top: 15px">
          <div style="margin-left: 15px">
            拉流：rtmp
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address3"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 40px">
              <a-icon @click="copySum(addressForm.address3)" type="copy" />
            </div>
          </div>
        </div>
        <div style="margin-top: 15px">
          <div style="margin-left: 15px">
            拉流：flv
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address4"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 40px">
              <a-icon @click="copySum(addressForm.address4)" type="copy" />
            </div>
          </div>
        </div>
        <div style="margin-top: 15px">
          <div style="margin-left: 15px">
            拉流：m3u8
          </div>
          <div style="display: flex">
            <div style="margin-top: 15px;margin-left: 15px">
              <a-textarea
                  style="width: 300px"
                  :disabled="true"
                  v-model="addressForm.address5"
                  :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </div>
            <div style="color: #1374ce;margin-left: 10px;margin-top: 50px">
              <a-icon @click="copySum(addressForm.address5)" type="copy" />
            </div>
          </div>
        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="resetFormAddress">
          关闭
        </a-button>
      </div>
    </a-drawer>
    <!--企业鸣谢-->
    <a-modal
        @cancel="()=>{ this.content_Enterprise_zone_visible = false
      this.Enterprise_zoneForm.isOpen_list = 0
      this.Enterprise_zoneForm.image = ''}"
        width="500px"
        v-model="content_Enterprise_zone_visible"
        title="企业鸣谢">
      <div>
        <span>状态：</span>
        <a-switch
            v-model="Enterprise_zoneForm.isOpen_list === 1 ? true:false"
            checked-children="开"
            un-checked-children="关"
            @click="RCYL_Enterprise_zone_change_isOpen(Enterprise_zoneForm.isOpen_list === 1 ?0:1)"
            default-checked />
      </div>
<!--      <div style="margin-top: 10px">-->
<!--        <span>名称：</span>-->
<!--        <a-tooltip placement="top">-->
<!--          <template slot="title">-->
<!--            <span>提示提示提示提示提示</span>-->
<!--          </template>-->
<!--          <a-icon type="info-circle" />-->
<!--        </a-tooltip>-->
<!--        <a-input style="width: 250px;margin-left: 5px" placeholder="请输入"/>-->
<!--      </div>-->
      <div style="margin-top: 15px;display: flex">
        <span style="width: 50px">图片：</span>
        <div>
          <div style="display: flex;width: 400px;">
            <div class="uploadImg" >
              <input
                  style="width: 100%;height:100%;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="content_RCYL_Enterprise_zone_image"
                  ref="content_RCYL_Enterprise_zone"
                  id="content_RCYL_Enterprise_zone_id" />
            </div>
            <div
                class="upload_LiveBroadcast">
              <img
                  v-if="Enterprise_zoneForm.image"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="Enterprise_zoneForm.image"
              />
            </div>
          </div>
          <div style="color: #a1a1a1;margin-top: 5px">注:尺寸 宽640，大小不超过1M</div>
        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  @click="()=>{ this.content_Enterprise_zone_visible = false
      this.Enterprise_zoneForm.isOpen_list = 0
      this.Enterprise_zoneForm.image = ''}">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="content_Enterprise_zone_Ok">
          确认
        </a-button>
      </div>
    </a-modal>
      <!--目录-->
      <a-modal
          @cancel="()=>{ this.content_catalogue_visible = false}"
          width="500px"
          v-model="content_catalogue_visible"
          title="目录">
        <div style="margin-top: 10px">
          <span>名称：</span>
          <a-tooltip placement="top">
            <template slot="title">
              <span>提示提示提示提示提示</span>
            </template>
            <a-icon type="info-circle" />
          </a-tooltip>
          <a-input style="width: 250px;margin-left: 5px" placeholder="请输入"/>
        </div>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button  @click="()=>{ this.content_catalogue_visible = false}">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="content_catalogue_true">
            确认
          </a-button>
        </div>
      </a-modal>
      <!--讨论-->
      <a-modal
          @cancel="()=>{ this.content_discussion_visible = false}"
          width="500px"
          v-model="content_discussion_visible"
          title="企业鸣谢">
        <div>
          <span>状态：</span>
          <a-switch
              v-model="discussion.isOpen_list === 1 ? true:false"
              checked-children="开"
              un-checked-children="关"
              @click="RCYL_discussion_change_isOpen(Enterprise_zoneForm.isOpen_list === 1 ?0:1)"
              default-checked />
        </div>
        <div style="margin-top: 10px">
          <span>名称：</span>
          <a-tooltip placement="top">
            <template slot="title">
              <span>提示提示提示提示提示</span>
            </template>
            <a-icon type="info-circle" />
          </a-tooltip>
          <a-input v-model="discussion.name" style="width: 250px;margin-left: 5px" placeholder="请输入"/>
        </div>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button  @click="()=>{ this.content_Enterprise_zone_visible = false}">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="content_discussion_true">
            确认
          </a-button>
        </div>
      </a-modal>
    <!--回放开启对话框-->
      <a-modal
          :width="680"
          v-model="roomIsOpen"
          title="注意"
          @ok="roomIsOpenSubmit"
          @cancel="roomIsOpenClose">
        <a-icon style="font-size:25px;color: #fa8358;margin-top: 5px" type="exclamation-circle" />
        <span style="margin-left: 10px;">直播间必须在关闭状态下才可以开启回访，是否关闭直播间？</span>
      </a-modal>
      <!--  模块功能  -->
      <a-drawer
          title="功能设置"
          :width="650"
          :visible="visibleFunction"
          :body-style="{ paddingBottom: '80px' }"
          @close="resetFunction"
      >
        <div>
          <a-alert
              message="注意事项"
              type="info"
              show-icon
          >
            <template #description>
              <div>
                1、详情是直播间必须开启的功能模块，不能关闭
              </div>
              <div>
                2、当直播间或回放的数量大于1，则自动显示目录，等于1则不显示
              </div>
              <div>
                3、功能的中英文名称用#间隔开，若不设置英文，则会在英文版本显示中文名称
              </div>
            </template>
          </a-alert>
          <a-table style="margin-top: 20px"
                   :columns="columnsFunction"
                   :pagination="false"
                   :rowKey='record=>record.id'
                   :data-source="dataFunction" bordered>
<!--            <template slot="titles" slot-scope="text, record">-->
<!--              {{record.title === ''}}-->
<!--            </template>-->
            <template slot="set" slot-scope="text, record">
              <a v-if="record.title === '企业专区'" @click="EnterpriseBtn(record.isEnterprise,record.id,record)">设置</a>
              <a v-if="record.title === '时长'" @click="durationBtn(record,record.id)">设置</a>
            </template>
            <template slot="name" slot-scope="text, record, index">
              <a-input v-model="record.name"/>
            </template>
            <template slot="action" slot-scope="text, record">
              <a-switch
                  v-if="record.title === '讨论' || record.title === '企业专区'|| record.title === '时长'|| record.title === '云展厅'"
                  v-model="record.isOpen"
                  checked-children="开"
                  un-checked-children="关"
                  default-checked />
            </template>
          </a-table>
        </div>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="resetFunction">
            取消
          </a-button>
          <a-button type="primary" @click="saveFunction">
            保存
          </a-button>
        </div>
      </a-drawer>
      <!--悬浮功能 -->
      <a-modal
          @cancel="suspensionClose"
          width="500px"
          v-model="suspensionVisible"
          title="悬浮功能">
        <a-form-model
            ref="suspension"
            :model="suspensionForm"
            :rules="suspensionFormRules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="状态">
            <a-switch
                v-model="suspensionForm.isopen === 1 ? true:false"
                checked-children="开"
                un-checked-children="关"
                @click="suspension_change_isOpen(suspensionForm.isopen === 1 ?0:1)"
                default-checked />
          </a-form-model-item>
          <a-form-model-item prop="icon" label="图标">
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    id="suspensionForm_imgId"
                    style="width: 100px;height: 100px;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="suspensionForm_img"
                    ref="suspensionForm_img_ref" />
              </div>
              <div
                  class="upload_poster">
                <img
                    v-if="suspensionForm.icon"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="suspensionForm.icon"
                />
              </div>
            </div>
            <div style="color: #a1a1a1">注:尺寸200*200，大小不超过500kb</div>
          </a-form-model-item>
          <a-form-model-item label="跳转链接" prop="url">
            <a-input
                v-model="suspensionForm.url"
                placeholder="请输入"
            />
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button  @click="suspensionClose">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="suspensionOk">
            确认
          </a-button>
        </div>
      </a-modal>
     <!-- 评分-->
      <a-drawer
          :get-container="false"
          @close="add_cancel"
          :width="1300"
          :body-style="{ paddingBottom: '80px' }"
          :visible="visibleLive"
          title="直播评分">
          <a-table
              bordered
              style="width: 100%;margin: auto"
              :rowKey='record=>record.id'
              :pagination="false"
              :columns="columnsModel"
              :data-source="dataModel">
            <template slot="TotalDemerit" slot-scope="text, record">
              <span :style="{color:(record.scoreManager *1 + record.scoreSale *1) ? 'red':''}">{{( record.scoreManager *1 + record.scoreSale *1) ?( record.scoreManager *1 + record.scoreSale *1) : 0 }}</span>
            </template>
            <template slot="scoreManager" slot-scope="text, record">
              <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
            </template>
            <template slot="scoreSale" slot-scope="text, record">
              <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
            </template>
            <template slot="deductSale" slot-scope="text, record">
              <span :style="{color:text ? 'red':''}">{{ text ? text : '无' }}</span>
            </template>

            <template slot="deductManager" slot-scope="text, record">
              <span :style="{color:text ? 'red':''}">{{ text ? text : '无' }}</span>
            </template>
          </a-table>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button  @click="add_cancel">
            取消
          </a-button>
        </div>

      </a-drawer>
<!--      添加专家组-->
      <a-modal v-model="visibleExpertGroup" title="添加专家组" @ok="expertGroupOk" @cancel="expertGroupNo">
        <a-select
            style="width: 300px"
            mode="multiple"
            show-search
            option-filter-prop="children"
            v-model="expertList"
            placeholder="请选择"
            @popupScroll="popupScroll_questionCode"
            @search="select_hospital"
        >
          <!-- @search="select_hospital"   @search="select_hospital"-->
          <a-select-option
              v-for="item in ExpertSelectList"
              :value="item.id"
              :key="item.id"
              :label="item.name"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-modal>
<!-- 底部   -->
    <div style="height: 60px"></div>
    <div style="width:100%;height:50px;position: fixed;bottom: 0;background-color: #f0f2f5;">
      <div
          style="width: 80%;display: flex;justify-content: right;margin-top: 10px">
        <a-button @click="returnBtn">返回上一层</a-button>
      </div>
    </div>
      <PublicSample :propList="propList"></PublicSample>

<!--      名单人员观看时长-->
      <ViewingDuration
          v-if="ViewingDurationVisible"
          :visible="ViewingDurationVisible"
          :conventionId="conventionId"
          v-on:ClosMain="ClosMain"
      />
    </a-spin>
  </div>
</template>

<script>
import moment from "moment";
import {update} from "../../utils/update";
import {sexDate, educationList} from "@/utils/dateReturn"; //性别
import {difference} from "../../utils/timeDifference";
import {
  delSetLiveList,
  getCommunictionList,
  getListContent,
  getOneLiveList,
  getSetLiveList,
  putSetLiveList,
  newPostSetLive,
  newPutSetLive,
  insCreditRecord,
  getDictionaryList,
  dictionary,
  upLoadSourceFile,
  delLoadSourceFile,
  updLiveStreamExperts, getLiveStreamExperts,
} from "../../service/liveMeet_api";
import E from "wangeditor";
import {
  getLiveOne,
  getplaybackList,
  postSetLive, postSetLiveAddress,
  putSetLive,
  putSetLive_xieyi,
  putTitle
} from "../../service/liveMeet_api2";
import {
  delMember,
  get_credit_registration_list, get_Tuition_fees_register_list,
  get_video_list, getImportExcelMemberTemplate,
  post_video_list,
  postUploadFile,
  put_RCYL_isOpen
} from "../../service/MedicalConference";
import {
  get_function_list,
  put_function_list,
  get_Playback_list_yun,
  put_Playback_UpOrDown, post_video_list_h, getMemberList, getMemberListTel, putMember, postMember
} from "@/service/MedicalConference_y";
import PublicSample from "@/utils/PublicSample";
import {getTitle} from "@/service/dataDictionary_api";
const columnsPlayback = [
  {
    title: '序号',
    scopedSlots: { customRender: 'numberIndex' },
    width: '8%',
  },{
    title: '视频Id',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' },
    width: '10%',
  },
  {
    title: '标题',
    dataIndex: 'name',
    width: '47%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '时长',
    dataIndex: 'duration',
    width: '10%',
    scopedSlots: { customRender: 'duration' },
  },{
    title: '类型',
    dataIndex: 'taskId',
    width: '10%',
    scopedSlots: { customRender: 'taskId' },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: '15%',
  },
];
const PlaybackTableData =[];
const dataModel = [];
const columnsModel = [
  {
    title: '类型',
    dataIndex: 'largeCategory',
    key: 'largeCategory',
    ellipsis: true,
    customRender (_, row) {
      return {
        children: row.largeCategory,
        attrs: {
          rowSpan: row.largeCategoryRowSpan
        }
      }
    }
  },
  {
    title: '内容',
    dataIndex: 'subclass',
    key: 'subclass',
  },
  {
    title: '得分值（分）',
    dataIndex: 'scoreAfter',
    key: 'scoreAfter',
    scopedSlots: { customRender: 'scoreAfter' },
  },
  {
    title: '总扣分',
    key: 'TotalDemerit',
    scopedSlots: { customRender: 'TotalDemerit' },
  },
  {
    title: '主管扣分',
    dataIndex: 'scoreManager',
    key: 'scoreManager',
    scopedSlots: { customRender: 'scoreManager' },
  },{
    title: '主管扣分理由',
    dataIndex: 'deductManager',
    key: 'deductManager',
    scopedSlots: { customRender: 'deductManager' },
  },{
    title: '业务扣分',
    dataIndex: 'scoreSale',
    key: 'scoreSale',
    scopedSlots: { customRender: 'scoreSale' },
  },{
    title: '业务扣分理由',
    dataIndex: 'deductSale',
    key: 'deductSale',
    scopedSlots: { customRender: 'deductSale' },
  },
];
import City from '@/assets/city.json'
import {getHospital} from "@/service/hospital_api";
import {getSchool} from "@/service/school_api";
import {getLoginDepartment} from "@/service/dataCleaned_api";
import {getFileByStreamId, getLiveScoreRecordsByStreamId} from "@/service/rating";
import {getExpertList} from "@/service/zoomMeet_api";
import ViewingDuration from "@/components/ViewingDuration";

export default {
  components: {
    ViewingDuration,
    PublicSample
  },
  data(){
    // this.cacheData = PlaybackTableData.map(item => ({ ...item }));
    return{
      ViewingDurationVisible:false,

      columnsModel,
      dataModel,

      propList:{
        visible:false,
        code:null,
      },

      targetUrl_top:'',
      tableRow:{},
      roomIsOpen:false,
      RCYL_Enterprise_zone_isOpen_id:'',
      spinning:false,
      content_Enterprise_zone_visible:false,
      content_discussion_visible:false,
      content_catalogue_visible:false,
      Enterprise_zoneForm:{
        isOpen_list:0,
        image:'',
      },
      discussion:{
        isOpen_list:0,
        name:'',
      },
      dataList:[],
      cacheData:[],
      is_delete:null,
      visibleModal:false,
      PlaybackTableData,
      columnsPlayback,
      editingKey: '',
      // open1: false,
      // open2: false,
      open3: false,
      open4: false,
      visibleTitle:'',
      visible:false,
      visibleOnWay:false,
      visibleDuration:false,
      visiblePlayback:false,
      visibleDeal:false,
      searchForm:{
        title:'',
        time:''
      },
      ExcelMember:false,
      changeFile:'',
      formData:{},
      columns:[
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: "6%",
          ellipsis: true,
        },
        {
          title: '标题',
          dataIndex: 'title',
          key: 'title',
          width: "15%",
          scopedSlots: { customRender: 'titleData' },
          ellipsis: true,
        },
        {
          title: '直播日期',
          dataIndex: 'liveDate',
          width: "10%",
          scopedSlots: { customRender: 'liveDate' },
        },
        // {
        //   title: '显示时间',
        //   dataIndex: 'showTime',
        //   width: "10%",
        //   // scopedSlots: { customRender: 'liveDate' },
        // },
        {
          title: '推流时间',
          dataIndex: 'endTime',
          key: 'endTime',
          width: "13%",
          scopedSlots: { customRender: 'endTime' },
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          width: "12%",
          scopedSlots: { customRender: 'address' },
        },
        {
          title: '回放',
          dataIndex: 'replayIsOpen',
          key: 'replayIsOpen',
          width: "7%",
          scopedSlots: { customRender: 'replayIsOpen' },
        },
        {
          title: '得分值',
          dataIndex: 'score',
          key: 'score',
          width: "7%",
          scopedSlots: { customRender: 'scoreValue' },
        },
        // {
        //   title: '登录方式',
        //   dataIndex: 'loginType',
        //   key: 'loginType',
        //   width: "12%",
        //   scopedSlots: { customRender: 'loginType' },
        // },
        {
          title: '运营负责人',
          dataIndex: 'leaderOperate',
          key: 'leaderOperate',
          width: "10%",
        },
        {
          dataIndex: 'isOpen',
          key: 'isOpen',
          width: "10%",
          slots: { title: 'customTitle' },
          scopedSlots: { customRender: 'isOpen' },
        },
        {
          title: '操作',
          key: 'action',
          width: "18%",
          scopedSlots: { customRender: 'action' },
        },
      ],
      data:[],
      labelCol: { span:4},
      labelCol_add: { span:5},
      labelColLogin: { span: 8},
      labelColduration: { span: 4},
      wrapperCol: { span: 14 },
      liveBroadcastType:[],
      uploadTypeList:[
        // {
        //   title:"姓名条",
        //   type:'XMT'
        // },{
        //   title:"KV图",
        //   type:'KV'
        // },{
        //   title:"海报",
        //   type:'HB'
        // },
      ],
      form: {
        cover_url:'',
        desc_image_url:'',
        live_date:undefined,
        live_start_time:'00:00:00',
        start_time:'00:00:00',
        end_time:'00:00:00',
        title:'',
        live_metting_id:'',
        show_time:'',
        created_by_id:'',
        //直播负责人
        leader_operate:undefined,
        //销售部负责人
        leader_sales:undefined,
        live_broadcastType:undefined, //直播类型
        Live_broadcast:1, //直播场次
        //开放范围
        isOpenScope:0,
      },
      streamId:null,
      designSourceFileVisible:false,
      fileSpin:false,
      imgList:{},
      designSourceFileForm:{
        uploadType:[],
        // switch:false,
        // 设计源文件
        fileList:[]
      },
      //保存设计源文件中的id
      fileListImg:[],
      // leaderOperateList:[],
      // leaderSalesList:[],
      PerList:[],
      leaderOperateList:[],
      rules: {
        cover_url: [{ required: true, message: '请上传', trigger: 'blur' }],
        desc_image_url: [{ required: true, message: '请上传', trigger: 'blur' }],
        live_date: [{ required: true, message: '请选择', trigger: 'change' }],
        live_start_time: [{ required: true, message: '请选择', trigger: 'change' }],
        start_time: [{ required: true, message: '请选择', trigger: 'change' }],
        end_time: [{ required: true, message: '请选择', trigger: 'change' }],
        title: [{ required: true, message: '请输入', trigger: 'blur' }],
        show_time: [{ required: true, message: '请输入', trigger: 'blur' }],
        leader_operate: [{ required: true, message: '请选择', trigger: 'blur' }],
        leader_sales: [{ required: true, message: '请选择', trigger: 'blur' }],
        live_broadcastType: [{ required: true, message: '请选择', trigger: 'blur' }],
        Live_broadcast: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      rulesRest: {
        cover_url: [{ required: true, message: '请上传', trigger: 'blur' }],
        desc_image_url: [{ required: true, message: '请上传', trigger: 'blur' }],
        live_start_time: [{ required: true, message: '请选择', trigger: 'change' }],
        title: [{ required: true, message: '请输入', trigger: 'blur' }],
        show_time: [{ required: true, message: '请输入', trigger: 'blur' }],
      },

      onWayForm:{
        onWay:'',
        status:0,
      },
      durationForm:{
        duration_is_open:false,
        duration_remark:'',
      },
      dealForm:{
        protocol_is_open:0,
        protocol_content:'',
      },
      PlaybackForm:{
        replay_is_open:0,
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      titleList:[],
      tablelist:[],
      tableCount:null,
      page_no:1,
      page_size:10,
      typeLoginStatus:true,
      //内容与编辑器
      //富文本显示内容编辑
      // 默认情况下，显示所有菜单
      editor_menus:[
        'head',
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'lineHeight',
        'foreColor',
        'backColor',
        'link',
        'list',
        'todo',
        'justify',
        'quote',
        'emoticon',
        'image',
        'table',
        'splitLine',
        'undo',
        'redo',
      ],
      editor:null,
      // dealForm:{
      //   isShow:1,
      //   include:''
      // },
      addressForm:{
        address1:'',
        address2:'',
        address3:'',
        address4:'',
        address5:'',
      },
      visibleAdress:false,
      listId:'',
      obsPushIp:false,
      timeList:[],
      durationId:'',
      dealId:'',
      playbackId:'',
      typeId:'',
      isOpen:0,
      OneLiveList:[],
      addIsShow:false,
      videoList:[],
      videoListId:'',
      addVideoBtn_select_page_no:1,
      selectTitle:'',
      liveDate_new:'',
      visibleFunction:false,
      columnsFunction:[
        {
          title: '功能',
          dataIndex: 'title',
        },
        {
          title: '名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '设置',
          scopedSlots: { customRender: 'set' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],

      dataFunction:[],
      functionList:{},
      functionId:null,
      suspensionId:null,
      suspensionVisible:false,
      suspensionForm:{
        isopen:0,
        url:'',
        icon:'',
      },
      suspensionFormRules: {
        url: [{ required: true, message: '请输入', trigger: 'blur' }],
        icon: [{ required: true, message: '请上传', trigger: 'blur' }],
      },
      listTel:null,
      columnsList:[
        {
          title: '序号',
          scopedSlots: { customRender: 'serialNumber' },
        }, {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '手机号',
          dataIndex: 'telephone',
          key: 'telephone',
        },
        {
          title: '身份',
          dataIndex: 'type',
          key: 'type',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '医院',
          dataIndex: 'hospitalName',
          key: 'hospitalName',
        },
        {
          title: '科室',
          dataIndex: 'hospitalOffices',
          key: 'hospitalOffices',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          key: 'jobTitle',
        },
        {
          title: '导入时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
          scopedSlots: { customRender: 'createdTime' },
        },{
          title: '操作',
          scopedSlots: { customRender: 'action' },
        },],
      dataLists:[],
      listCount:0,
      listPageNo:1,
      listpageSize:10,
      type:null,
      conventionId:null,

      addPersonVisile:false,
      personnelListForm:{
        type:'1001',
        province:undefined,
        hospitalName:undefined,
        school:undefined,
        certStatus:"UNCERTIFIED",
        registerChannel:"后台添加",
      },
      personnelListRules:{
        tel:[
            {required: true, message: '请输入', trigger: 'blur'},
            { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
            ],
        name:[{required: true, message: '请输入', trigger: 'blur'}],
      },
      sexList:sexDate(),
      educationList:educationList(),
      City:City,
      jobTitleList:[],
      provincePtions:[],

      hosNamePageNo:1,
      hosName:'',
      hospitalNameList:[], //医院列表
      certStatus:[
        {
          value:'UNCERTIFIED',
          name:'未认证'
        },{
          value:'IN_REVIEW',
          name:'审核中'
        },
        {
          value:'CERTIFIED',
          name:'已认证'
        }
      ],
      schoolNamePageNo:1,
      schoolName:'',
      schoolListData:[], //学校列表

      hospitalOfficesList:[],

      addPersonDisabled:true,//初始状态下默认为不能编辑状态

      listText:[],

      visibleLive:false,

      visibleExpertGroup:false,
      expertList:[],
      ExpertSelectList:[],

      expertPageNum:1,
      expertName:'',
    }
  },
  created() {
    let liveImg = JSON.parse(localStorage.getItem('liveImg'));
    this.leaderOperateList = liveImg.leaderOperate.split(',');
    this.type = this.$route.query.type;
    this.conventionId = this.$route.query.conventionId;
    this.live_metting_id = this.$route.query.live_metting_id;
    this.getLogData();
  },
  mounted() {
    this.getTitle()
    this.getTableList()
    this.getPCH()
    this.getHospitalOfficesList()
    this.getJobTitleList(this.personnelListForm.type)
  },
  computed:{
    // liveImg(){
    //   return this.$store.state.liveImg
    // },
    liveHistory(){
      return this.$store.state.liveMeetHistory
    }
  },
  methods:{
    ClosMain(val){
      this.ViewingDurationVisible = val;
    },
    openSample(code){
      // code加上时间防止重复
      this.propList = {
        visible:true,
        code:code+'202304031427',
      }
    },
    moment,
    //设置不可选的时间
    disabledStartHours(){
      if(this.form.start_time){
        let count = parseInt(this.form.start_time.slice(0,2))
        return this.range(0, 24).splice(0, count)
      }
    },
    disabledStar(){
      //// 12:20:20
      if(this.form.start_time){
      let count = parseInt(this.form.start_time.slice(3,5))
      return this.range(0, count)}
    },
    disabledStartSeconds(){
      if(this.form.start_time){
      let count = parseInt(this.form.start_time.slice(6,8))
      return this.range(0, count)}
    },
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    //新增对话框富文本编辑器
    divRedio(){
      //初始化富文本编辑器
      const editor = new E(this.$refs.editor)
      this.editor = editor
      editor.config.height = 300
      //配置菜单栏
      editor.config.menus = this.editor_menus
      // //限制一次最多能传几张图片
      // editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图片
      editor.create()
    },
    //获取直播标题与会议地址
    async getTitle() {
      this.spinning = true
      let id = this.live_metting_id
      const response = await getListContent(id)
      if(response.code === 0){
        this.titleList = response.data
        if(this.targetUrl_top === ''){
          if(this.type === 'CONVENTION') {
            //多会场
            this.targetUrl_top = response.data.targetUrl + '&conventionId=' + this.conventionId
          }else {
            this.targetUrl_top = response.data.targetUrl
          }
        }
      }else {
        this.$message.warning("出错，请联系管理员！"+response.message)
      }
      this.spinning = false
    },
    //获取设置直播列表
    async getTableList(page_no,page_size,title,live_date) {
      this.spinning = true
     let live_metting_id =  this.live_metting_id
      let data = {
        page_no:page_no,
        page_size:page_size,
        title:title,
        liveDate:live_date,
        liveMettingId:live_metting_id
      }
      const response = await getSetLiveList(data)
      if(response.code === 0){
        this.tablelist = response.data.rows
        this.tableCount = response.data.count
      }else {
        this.$message.warning("出错，请联系管理员！"+response.message)
      }
      this.spinning = false
    },
    //获取单个直播间
    async getOneLisat(id) {
      this.spinning = true
      const response = await getOneLiveList(id)
      if (response.code === 0) {
        this.OneLiveList = response.data;
      } else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    //分页
    listChange(page_no,page_size){
      let form = this.searchForm
      this.page_no = page_no
      this.page_size = page_size
      this.getTableList(page_no,page_size,form.title,form.time)
    },
    onChange(){
      this.searchForm.time = moment(this.searchForm.time).format('YYYY-MM-DD')
    },
    dateChange(date, dateString){
      this.form.live_date = dateString
    },
    onClose(){
      this.visible = false
    },
    async upDetailsCover() {
      let form = this.form;
      let _this = this;
      let inputDOM = this.$refs.deyails.files[0];
      //判断图片大小
      if (inputDOM.size < 2097152) {
        //判断图片尺寸
        if (inputDOM) {
          var reader = new FileReader()
          reader.onload = function (event) {
            var base64 = event.target.result
            var img = document.createElement('img')
            img.src = base64
            img.onload = async function () {
              if (img.width <= 1920) {
                const image = await update(inputDOM)
                if (image.code === 0) {
                  form.cover_url = image.data.url
                  _this.$message.success("上传成功")
                } else {
                  _this.$message.warning(image.message)
                }
              } else {
                _this.$message.warning("注:建议尺寸1920px*1080px，宽度不能超过1920px !")
              }
            }
          }
          reader.readAsDataURL(inputDOM)
        }
      } else {
        this.$message.warning("请上传小于2M的图片！")
      }
      document.getElementById('deyalisId').value = null
    },
    async upPosterCover() {
      let form = this.form;
      let _this = this;

      let inputDOM = this.$refs.descImageUrl.files[0];
      //判断图片大小
      if (inputDOM.size < 3145728) {
        //判断图片尺寸
        if (inputDOM) {
          var reader = new FileReader()
          reader.onload = function (event) {
            var base64 = event.target.result
            var img = document.createElement('img')
            img.src = base64
            img.onload = async function () {
              if (img.width <= 1920) {
                const image = await update(inputDOM)
                if (image.code === 0) {
                  form.desc_image_url = image.data.url
                  _this.$message.success("上传成功")
                } else {
                  _this.$message.warning(image.message)
                }
              } else {
                _this.$message.warning("注:宽度不能超过1920px !")
              }
            }
          }
          reader.readAsDataURL(inputDOM)
        }
      } else {
        this.$message.warning("请上传小于3M的图片！")
      }
      document.getElementById('descImageUrlId').value = null
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    addClose(){
      this.$refs.ruleFormAdd.resetFields();
      Object.keys(this.form).forEach(key => this.form[key]= '');
      this.form.live_date = undefined
      this.form.start_time = '00:00:00'
      this.form.end_time = '00:00:00'
      this.form.live_start_time = '00:00:00'
      this.form.leader_operate = undefined
      this.form.live_broadcastType = undefined
      this.form.Live_broadcast = 1
      this.form.leader_sales = undefined
      this.form.isOpenScope = 0
      this.visible = false
      this.obsPushIp = false
    },
    onSubmit() {
      this.$refs.ruleFormAdd.validate(async valid => {
        if (valid) {
          //获取本地存储信息，并转换为Json格式
          const userData = JSON.parse(window.localStorage.getItem("user_info"))
          let form = this.form
          if(this.visibleTitle === '修改'){
          //  修改
            let dataRest = {
              "id": this.listId,
              "coverUrl": form.cover_url,
              "descImageUrl": form.desc_image_url,
              "liveDate": form.live_date.slice(0,10),
              "live_start_time": this.form.live_date.slice(0,10) + ' ' + this.form.live_start_time,
              "startTime": this.form.live_date.slice(0,10) + ' ' + this.form.start_time,
              "endTime": this.form.live_date.slice(0,10) + ' ' + this.form.end_time,
              "title": form.title,
              "showTime": form.show_time,
              // "leaderOperate": form.leader_operate.toString(),
              "leaderOperate": form.leader_operate,
              "leaderSales": form.leader_sales,
              "isOpenScope": form.isOpenScope,

              //直播类型：liveTypeId
              "liveTypeId":form.live_broadcastType,
              // 直播场次：numberOfLive
              "numberOfLive":form.Live_broadcast,
            }
            await this.restList(dataRest)
          }else {
          //  新增
            let dataAdd = {
              "liveMettingId": parseInt(this.live_metting_id),
              "coverUrl": form.cover_url,
              "descImageUrl": form.desc_image_url,
              "liveDate": form.live_date.slice(0,10),
              "live_start_time": this.form.live_date.slice(0,10) + ' ' + this.form.live_start_time,
              "startTime": this.form.live_date.slice(0,10) + ' ' + this.form.start_time,
              "endTime": this.form.live_date.slice(0,10)  + ' ' + this.form.end_time,
              "title": form.title,
              "showTime": form.show_time,
              // "leaderOperate": form.leader_operate.toString(),
              "leaderOperate": form.leader_operate,
              "leaderSales": form.leader_sales,
              "isOpenScope": form.isOpenScope,
              "conventionId":this.$route.query.conventionId,
              //多会场新新增传值1，其他为null
              "isConven" : this.type === 'CONVENTION'?1:null,

              //直播类型：liveTypeId
              "liveTypeId":form.live_broadcastType,
              // 直播场次：numberOfLive
              "numberOfLive":form.Live_broadcast,
            }
            // console.log(dataAdd)
            await this.addList(dataAdd)
          }
          //清空关闭以及刷新列表
          this.addClose()
          await this.getTableList(this.page_no,this.page_size,form.title,form.time)
        } else {
          this.$message.warning("有空~~")
          return false;
        }
      });
    },
    //新增
    async addList(data) {
      const response = await newPostSetLive(data)
      if (response.code === 0) {
        this.$message.success("添加成功")
      } else {
        this.$message.warning(response.message)
      }
    },
    //修改
    async restList(data) {
      const response = await newPutSetLive(data)
      if (response.code === 0) {
        this.$message.success("修改成功")
      } else {
        this.$message.warning(response.message)
      }
    },
    async addBtn() {
      let liveImg = JSON.parse(localStorage.getItem('liveImg'))
      this.visibleTitle = '新增'
      this.visible = true
      //全部人员
      await this.getPerList()
      //获取关闭或开启的直播类型数据
      await this.dictionaryList()
      //赋值---将直播的图片，直播日期，开始时间，推流时间与直播会议同步

      this.form.cover_url = liveImg.detailCoverUrl
      this.form.desc_image_url = liveImg.descImageUrl
      //直播开始时间
      this.form.live_start_time = liveImg.sortTime.slice(11,19)
      // this.form.start_time = liveImg.sortTime.slice(11,19)
      this.form.start_time = '00:00:00'
      this.form.end_time = '23:59:59'
      //直播日期
      this.form.live_date = liveImg.sortTime
      //负责人
      // this.form.leader_operate = liveImg.leaderOperate
      this.form.leader_sales = liveImg.leaderSales

      //获取时间列表
      this.timeList = this.titleList.dateRange
    },
    selectTime(date, dateString){
      this.form.live_start_time = dateString
    },
    selectTime1(date, dateString){
      this.form.start_time = dateString
    },
    selectTime2(date, dateString){
      this.form.end_time = dateString
    },
    async listPersons() {
      this.listPageNo = 1
      // 当前会议只有关联会议的情况下才可以导入名单
      if (this.titleList.conventionId) {
        await this.get_columnsList()
        this.ExcelMember = true
      } else {
        this.$message.warning("请先关联医学会议在进行人员名单导入~")
      }
    },
    listPersonsModel(){
      this.ViewingDurationVisible = true;
    },
    //修改
    async restBtn(row) {
      this.visibleTitle = '修改'
      // 判断当前时间是不是大于要修改的时间
      if(row.startTime !== undefined && row.startTime !== null && row.startTime !== ''){
        // let date1 = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          await this.getOneLisat(row.id)
          this.visible = true
          this.listId = row.id
          //判断推流地址的有无，生成推流地址后不能修改当前直播间的时间
          this.obsPushIp = row.obsPushIp
          //  赋值
          let form = this.form
          let list = this.OneLiveList
          //赋值
          this.liveDate_new = list.liveDate

          form.cover_url = list.coverUrl
          form.desc_image_url = list.descImageUrl

          form.live_date = list.liveDate

          if(list.live_start_time){
            let live_start_time = list.live_start_time
            form.live_start_time = live_start_time.slice(11,19)
          }
          // form.start_time = '00:00:00'

          if(list.startTime){
            let startTime = list.startTime
            form.start_time = startTime.slice(11,19)
          }
          form.end_time = '00:00:00'

          if(list.endTime){
            let endTime = list.endTime
            form.end_time = endTime.slice(11,19)
          }

          form.title = row.title
          form.show_time = list.showTime
          if(row.leaderOperate){
            // form.leader_operate = row.leaderOperate.split(',')
            form.leader_operate = row.leaderOperate
          }
          if(row.leaderSales){
            form.leader_sales = row.leaderSales;
          }
          //开放范围
          form.isOpenScope = row.isOpenScope
          form.live_broadcastType = row.liveTypeId //直播类型
          form.Live_broadcast = row.numberOfLive //直播类型

          //全部人员
          await this.getPerList()
          //获取关闭或开启的直播类型数据
          await this.dictionaryList()
      }
    },
    //修改协议公用
    async modification_xieyi(id,data) {
      const response = await putSetLive_xieyi(id,data)
      if (response.code === 0) {
        this.$message.success("修改成功")
        let form = this.searchForm
        await this.getTableList(this.page_no,this.page_size,form.title,form.time)
      } else {
        this.$message.warning("修改失败" + response.message)
      }
    },
    //修改外包公用
    async modification(id,data) {
      const response = await putSetLive(id,data)
      if (response.code === 0) {
        this.$message.success("修改成功")
        let form = this.searchForm
        await this.getTableList(this.page_no,this.page_size,form.title,form.time)
      } else {
        this.$message.warning("修改失败" + response.message)
      }
    },
    //修改公用
    async modification_icu(id,data) {
      const response = await putSetLiveList(id,data)
      if (response.code === 0) {
        this.$message.success("修改成功")
        let form = this.searchForm
        await this.getTableList(this.page_no,this.page_size,form.title,form.time)
      } else {
        this.$message.warning("修改失败" + response.message)
      }
    },

    //登陆方式
    async onWayBtn(type, id) {
      //获取是否完善信息
      await this.getOneLisat(id)
      this.visibleOnWay = true
      this.onWayForm.onWay = type
      this.typeId = id
      if (type === 'phone_code') {
        this.typeLoginStatus = true
        this.onWayForm.status = this.OneLiveList.isNeedPerfectInfo
      } else {
        this.typeLoginStatus = false
      }
    },
    //登陆方式对话框确认按钮
    onWaySubmit(){
        const data = {
          loginType:this.onWayForm.onWay,
          isNeedPerfectInfo:this.onWayForm.status,
        }
        //修改
        this.modification_icu(this.typeId,data)
        this.getTableList(this.page_no,this.page_size)
        this.visibleOnWay = false
    },
    selectLoginChange(){
      let onWay = this.onWayForm.onWay
      if(onWay === 'phone_code'){
        this.typeLoginStatus = true
      }else {
        this.typeLoginStatus = false
      }
    },
    handleModal() {
        this.visibleModal = false;
    },
    selectStatusChange(){
    },
    switchChange(checked){
      this.durationForm.duration_is_open = checked;
    },
    async durationBtn(record,id){
      let row = this.functionList;
      await this.getOneLisat(row.id);
      let list = this.OneLiveList;
      this.durationForm.duration_is_open = list.durationIsOpen ? true:false;
      this.durationForm.duration_remark = list.durationRemark;
      this.durationId = id;
      this.visibleDuration = true
    },
    handleDuration(){
      this.visibleDuration = false;
    },
    //选择时长确认
    async durationSubmit() {
      let data = {
        durationIsOpen: this.durationForm.duration_is_open ? 1:0,
        durationRemark: this.durationForm.duration_remark,
      }
      const response = await put_function_list(this.functionId, data)
      if (response.code === 0) {
        this.$message.success("保存成功~");
        this.dataFunction[5].isOpen = this.durationForm.duration_is_open;
        this.handleDuration();
      } else {
        this.$message.success(response.message)
      }
    },
    //回访设置
    playbackBtn(isOpen, id,row) {
      this.tableRow = row
      this.visiblePlayback = true
      this.playbackId = id
      this.PlaybackForm.replay_is_open = parseInt(isOpen)
      // //获取回放对话框中的table
      this.delModelList(id)
    },
    async delModelList(testId) {
      // const response = await getplaybackList(testId)  //外包接口
      const response = await get_Playback_list_yun(testId)
      if (response.code === 0) {
        // this.dataList = response.data.video_list
        this.dataList = response.data
        let list = this.dataList
        //每次请求前清空
        this.PlaybackTableData = []
        // if(this.data === []){
        for (let i = 0; i < list.length; i++) {
          this.PlaybackTableData.push({
            id: '',
            name: '',
            duration: '',
            taskId: null,
            addIsShow:0,
          })
          this.PlaybackTableData[i].name = list[i].title
          this.PlaybackTableData[i].id = list[i].id
          this.PlaybackTableData[i].taskId = list[i].taskId
          this.PlaybackTableData[i].duration = list[i].duration
        }
        // }
        this.cacheData = this.PlaybackTableData.map(item => ({...item}));

      } else {
        this.$message.warning("失败，请联系管理员" + response.message)
      }
    },
    async upOrDownBtn(type, id) {
      const response = await put_Playback_UpOrDown(id,type)
      if (response.code === 0) {
        this.$message.success("操作成功~")
        await this.delModelList(this.playbackId)
      } else {
        this.$message.success(response.message)
      }
    },
    resetFormPlayback(){
      this.visiblePlayback = false
      this.PlaybackTableData = []
      this.videoList = []
    },
    async onSubmitPlayback() {
      this.PlaybackTableData = []
      this.visiblePlayback = false
    },


    handleChange(value, id, column) {
      const newData = [...this.PlaybackTableData];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.PlaybackTableData = newData;
      }
    },
    edit(id) {
      const newData = [...this.PlaybackTableData];
      const target = newData.find(item => id === item.id);
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.PlaybackTableData = newData;
      }
    },
    async putLive(id, data) {
      const response = await putTitle(id, data)
      if (response.code === 0) {
        this.$message.success("操作成功")
      } else {
        this.$message.warning("出错，请联系管理员", response.message)
      }
    },
    //保存
    async save(id, row) {
      if (!row.addIsShow) {
        const newData = [...this.PlaybackTableData];
        const newCacheData = [...this.cacheData];
        const target = newData.find(item => id === item.id);
        const targetCache = newCacheData.find(item => id === item.id);
        if (target && targetCache) {
          delete target.editable;
          this.PlaybackTableData = newData;
          Object.assign(targetCache, target);
          this.cacheData = newCacheData;
        }
        this.editingKey = '';
        //修改标题
        const PlaybackTableData = {
          title: row.name
        }
        await this.putLive(id, PlaybackTableData)
      } else {
        //选择的视频库id,和列表id
        let videoManegeId = this.videoListId
        let streamId = this.playbackId
        await this.postVideo(streamId, videoManegeId)
        row.editable = 0
        row.addIsShow = 0
        this.editingKey = '';
        //  刷新列表
        await this.delModelList(streamId)
      }
      //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      this.videoList = []
    },
    async postVideo(streamId,videoManegeId) {
      const response = await post_video_list(streamId,videoManegeId)   //外包接口
      // const response = await post_video_list_h(streamId,videoManegeId)  //内部
      if (response.code === 0) {
        this.$message.success("操作成功")
      } else {
        this.$message.warning("出错" + response.message)
      }
    },
    //取消
    cancel(id,row) {
      if(!row.addIsShow) {
        const newData = [...this.PlaybackTableData];
        const target = newData.find(item => id === item.id);
        this.editingKey = '';
        if (target) {
          Object.assign(target, this.cacheData.find(item => id === item.id));
          delete target.editable;
          this.PlaybackTableData = newData;
        }
      }else {
        this.PlaybackTableData.pop()
      }
    },
    async confirmDel(id) {
      this.is_delete = 1
      const data = {
        is_delete: this.is_delete
      }
      await this.putLive(id, data)
      //  清空
      this.PlaybackTableData = []
      await this.delModelList(this.playbackId)
    },
    cancelDel(){
      this.is_delete = 0
    },
    //HTML反转义
    HTMLDecode(text) {
      var temp = document.createElement("div");
      temp.innerHTML = text;
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
    //获取外包接口单个详情
    async getOneLiveList(id) {
      const response = await getLiveOne(id)
      if(response.code === 0){
        // this.dealForm.protocol_content = this.editor.txt.html(`${response.data.protocol_content}`)
        this.editor.txt.html(response.data.protocol_content);
        this.editor.txt.html(this.HTMLDecode(response.data.protocol_content))

      }else {
        this.$message.warning("失败"+response.message)
      }
    },
    // 协议设置
    dealBtn(isOpen,id,row){
      this.visibleDeal = true
      this.dealId = id
      this.dealForm.protocol_is_open = parseInt(isOpen)
      this.getOneLiveList(row.id)
      //等待dom元素更新
      this.$nextTick(() => {
        //判断文本编辑器是否创建，创建就销毁，防止重复创建
        if(this.editor === null){
          //初始化富文本编辑器
          this.divRedio()
        }else {
          //销毁
          this.editor.destroy()
          this.divRedio()
        }
      })
    },
    resetFormDeal(){
      this.visibleDeal = false
    },
    onSubmitDeal(){
      this.dealForm.protocol_content = this.editor.txt.html()
      if(this.dealForm.protocol_content !== ''){
        this.modification_xieyi(this.dealId,this.dealForm)
        this.visibleDeal = false
        let form = this.searchForm
        this.getTableList(this.page_no,this.page_size,form.title,form.time)
        this.dealForm.protocol_content = ''
      }else {
        this.$message.warning("请填写内容")
      }
    },
    //查询按钮
    seachBtn(){
     let form = this.searchForm
      this.getTableList(this.page_no,this.page_size,form.title,form.time)
    },
    restCloseBtn(){
      let form = this.searchForm
      form.time = ''
      form.title = ''
      this.getTableList(this.page_no,this.page_size,form.title,form.time)
    },
    switchDealChange(checked){
      this.dealForm.protocol_content = this.editor.txt.html()
      if(this.dealForm.protocol_content !== ''
          && this.dealForm.protocol_content !== undefined
          && this.dealForm.protocol_content !== null){
        this.dealForm.protocol_is_open = checked
      }else {
        this.$message.warning("请先输入内容后在开启！")
      }
    },
    switchPlaybackChange(checked){
      if(this.tableRow.isOpen === 0 || checked === 0){
        this.PlaybackForm.replay_is_open = checked
        const data = {
          replay_is_open:this.PlaybackForm.replay_is_open
        }
        this.modification(this.playbackId,data)
      }else {
        this.roomIsOpen = true
      }
    },
    //顶部复制
    copy () {
      if(this.type !== 'CONVENTION' || (this.type === 'CONVENTION' && this.conventionId)){
        //创建input标签
        var input = document.createElement('input')
        //将input的值设置为需要复制的内容
        input.value = this.targetUrl_top;
        //添加input标签
        document.body.appendChild(input)
        //选中input标签
        input.select()
        //执行复制
        document.execCommand('copy')
        //成功提示信息
        this.$message.success('success!')
        //移除input标签
        document.body.removeChild(input)
      }else {
        this.$message.warning("请先去九宫格中关联医学会议！")
      }
    },
    copySum (item) {
      //创建input标签
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = item;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success('success!')
      //移除input标签
      document.body.removeChild(input)
    },
    resetFormAddress(){
      this.visibleAdress = false
    },
    //地址打开对话框
    async addresBtn(list) {
      await this.getOneLisat(list.id)
      this.visibleAdress = true
      //传id，获取单个直播列表中的地址
      //直播地址赋值
      let form = this.addressForm
      let row = this.OneLiveList
      form.address1 = row.obsPushIp
      form.address2 = row.obsPushName
      form.address3 = row.pullRtmpIp
      form.address4 = row.pullFlvIp
      form.address5 = row.pullM3u8Ip
    },
    async addressTrueBtn(row) {
      let data = {
        stream_id:row.id,
        end_time:row.endTime
      }
      const response = await postSetLiveAddress(data)
      if (response.code === 0) {
        this.$message.success("操作成功");
        //  刷新列表
        let form = this.searchForm
        await this.getTableList(this.page_no,this.page_size,form.title,form.time)
      } else {
        this.$message.warning(response.message)
      }
    },
    returnBtn(){
      // 判断是菜单点击进入
      this.liveHistory.isRouter = false
      window.history.back()
    },
    // async delConfirm(id) {
    //   const response = await delSetLiveList(id)
    //   if(response.code === 0){
    //     this.$message.success("删除成功！")
    //     let form = this.searchForm
    //     await this.getTableList(this.page_no ,this.page_size,form.title,form.time)
    //   }else {
    //     this.$message.warning("失败请联系管理员！" + response.message)
    //   }
    // },
    // async EnterpriseBtn(is_open, id, row) {
    async EnterpriseBtn() {
      let row = this.functionList;
      this.RCYL_Enterprise_zone_isOpen_id = row.id;
      await this.getOneLisat(row.id);
      this.content_Enterprise_zone_visible = true;
      let list = this.OneLiveList;
      let form = this.Enterprise_zoneForm;
      this.Enterprise_zoneForm.isOpen_list = list.isEnterprise;
      this.Enterprise_zoneForm.image = list.enterpriseImage;
    },
    catalogueBtn(is_open, id, row){
      this.content_catalogue_visible = true
    },
    discussionBtn(is_open, id, row){
      this.content_discussion_visible = true
    },
    tableIsOpen(is_open,id){
      let data = {
        isOpen:is_open
      }
      this.modification_icu(id,data)
    },
    RCYL_Enterprise_zone_change_isOpen(isOpen){
      if(this.Enterprise_zoneForm.image !== undefined
          && this.Enterprise_zoneForm.image !== null
          && this.Enterprise_zoneForm.image !== ''){
        this.Enterprise_zoneForm.isOpen_list = isOpen
      }else {
        this.$message.warning('请先上传图片！')
      }
    },
    RCYL_discussion_change_isOpen(isOpen){
      // if(this.Enterprise_zoneForm.image !== undefined
      //     && this.Enterprise_zoneForm.image !== null
      //     && this.Enterprise_zoneForm.image !== ''){
      //   this.Enterprise_zoneForm.isOpen_list = isOpen
      // }else {
      //   this.$message.warning('请先上传图片！')
      // }
    },
    async content_RCYL_Enterprise_zone_image() {
      let inputDOM = this.$refs.content_RCYL_Enterprise_zone.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.Enterprise_zoneForm.image = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
      document.getElementById('content_RCYL_Enterprise_zone_id').value = null
    },
    //共用修改接口
    async rest_RCYL_isOpen(id,data) {
      const response = await put_RCYL_isOpen(id,data)
      if(response.code === 0){
        this.$message.success("修改成功！")
        //  刷新列表
        let form = this.searchForm
        await this.getTableList(this.page_no,this.page_size,form.title,form.time)
      }else {
        this.$message.warning("失败！"+response.message)
      }

    },
    content_Enterprise_zone_Ok(){
      let form = this.Enterprise_zoneForm
      let data = {
        "isEnterprise":form.isOpen_list,
        "enterpriseImage":[form.image]
      }
      this.dataFunction[3].isOpen = this.Enterprise_zoneForm.isOpen_list ? true:false;
      let id = this.RCYL_Enterprise_zone_isOpen_id
      this.rest_RCYL_isOpen(id,data)
      this.content_Enterprise_zone_visible = false
      this.Enterprise_zoneForm.isOpen_list = 0
      this.Enterprise_zoneForm.image = '';

    },
    async addVideoList(page_no,value) {
      let data = {
        page_no:page_no,
        title:value,
      }
      const response = await get_video_list(data)
      if (response.code === 0) {
        let list  = response.data.rows
        for (let i=0;i<list.length;i++){
          this.videoList.push({
            duration: list[i].duration,
            id: list[i].id,
            remark: list[i].remark,
            title: list[i].title,
          })
        }
      } else {
        this.$message.warning("失败"+response.message)
      }
    },
    //滚动条监听
    popupScroll_addVideoBtn(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.addVideoBtn_select_page_no++
        this.addVideoList(this.addVideoBtn_select_page_no,this.selectTitle)
      }
    },
    select_add_search(value) {
      //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      this.videoList = []
      this.selectTitle = value
      this.addVideoList('',this.selectTitle)
    },
    async addVideoBtn() {
      //获取视频库列表
      await this.addVideoList()
      this.PlaybackTableData.push({
        name: '',
        duration: '',
        taskId: null,
        editable: 1,
        addIsShow:1
      })
    },

    select_add(title,id){
      this.videoListId = id
    },

    async roomIsOpenSubmit() {
      //修改直播间为关闭状态
      let data = {
        isOpen: 0
      }
      await this.modification_icu(this.playbackId, data)
      this.roomIsOpen = false
      //修改回放为开启状态
      this.PlaybackForm.replay_is_open = 1
      const data2 = {
        replay_is_open: this.PlaybackForm.replay_is_open
      }
      await this.modification(this.playbackId, data2)
    },
    roomIsOpenClose(){
      this.roomIsOpen = false
    },
    handleMenuClick(value) {
      if(value === '2'){
        this.targetUrl_top  = this.targetUrl_top + '&memberId=78ce3cd0-e42b-11ea-8c91-23f1f183e1f9'
      }else {
        this.targetUrl_top  = this.titleList.targetUrl
      }
    },
    content_catalogue_true(){
      this.content_catalogue_visible = false
    },
    content_discussion_true(){
      this.content_discussion_visible = false
    },
    resetFunction(){
      this.dataFunction=[];
      this.durationForm = {
        duration_is_open:false,
        duration_remark:'',
      }
      this.visibleFunction = false;
    },
    async saveFunction() {
      let row = this.dataFunction
      let data = []
      if(this.type === 'CONVENTION'){
      //  多会场
        data = {
          mkDetailName: row[0].name,
          //多会场情况下没有目录
          // mkDirectoryName: row[1].name,
          mkConvenName: row[1].name,
          mkDiscussName: row[2].name,
          mkCompanyName: row[3].name,
          mkGalleryName: row[4].name,
          mkDurationName: row[5].name,

          discussIsOpen: row[2].isOpen === true?1:0,
          isEnterprisel: row[3].isOpen === true?1:0,
          isGallery: row[4].isOpen === true?1:0,
          durationIsOpen: row[5].isOpen === true?1:0,
        }
      }else {
        data = {
          mkDetailName: row[0].name,
          mkDirectoryName: row[1].name,
          mkDiscussName: row[2].name,
          mkCompanyName: row[3].name,

          discussIsOpen: row[2].isOpen === true?1:0,
          isEnterprisel: row[3].isOpen === true?1:0,
        }
      }

      const response = await put_function_list(this.functionId,data)
      if(response.code === 0){
        this.$message.success("保存成功~")
        this.visibleFunction = false
      }else {
        this.$message.success(response.message)
      }
      this.dataFunction=[]
    },
    async functionBtn(row) {
      this.functionList = row;
      this.functionId = row.id
      await this.getFunction(row.id)
      this.visibleFunction = true
    },
    //根据id查询直播间功能设置
    async getFunction(id) {
      const response = await get_function_list(id)
      if(response.code === 0){
        let list = response.data
        if(this.type === 'CONVENTION'){
          //多会场
          this.dataFunction.push(
              {
                id:1,
                title:'详情',
                name:list[0].mkDetailName,
                isOpen:false,
              },
              // {
              //   id:2,
              //   title:'目录',
              //   name:list[0].mkDirectoryName,
              //   isOpen:false,
              // },
              {
                id:2,
                title:'多会场',
                name:list[0].mkConvenName,
                isOpen:false,
              },{
                id:3,
                title:'讨论',
                name:list[0].mkDiscussName,
                isOpen:list[0].discussIsOpen === 1 ? true:false,
              },{
                id:4,
                title:'企业专区',
                name:list[0].mkCompanyName,
                isOpen:list[0].isEnterprisel === 1 ? true:false,
              },{
                id:5,
                title:'云展厅',
                name:list[0].mkGalleryName,
                isOpen:list[0].isGallery === 1 ? true:false,
              },{
                id:6,
                title:'时长',
                name:list[0].mkDurationName,
                isOpen:list[0].durationIsOpen === 1 ? true:false,
                durationRemark:list[0].durationRemark //时长备注
              },
          )
        }else {
          this.dataFunction.push(
              {
                id:1,
                title:'详情',
                name:list[0].mkDetailName,
                isOpen:false,
              },{
                id:2,
                title:'目录',
                name:list[0].mkDirectoryName,
                isOpen:false,
              },{
                id:3,
                title:'讨论',
                name:list[0].mkDiscussName,
                isOpen:list[0].discussIsOpen === 1 ? true:false,
              },{
                id:4,
                title:'企业专区',
                name:list[0].mkCompanyName,
                isOpen:list[0].isEnterprisel === 1 ? true:false,
                durationRemark:list[0].durationRemark
              },
          )
        }
      }
    },
    suspensionBtn(row){
      this.suspensionId = row.id
      this.getSusContentList(row.id)
      this.suspensionVisible = true
    },
    async getSusContentList(id) {
      const response = await get_function_list(id)
      if(response.data.funXf !== null){
        if(response.code === 0){
          if(response.data[0].funXf){
            this.suspensionForm.url = response.data[0].funXf.url
            this.suspensionForm.isopen = response.data[0].funXf.isopen
            this.suspensionForm.icon = response.data[0].funXf.icon
          }
        }
      }
    },
    suspensionClose(){
      this.suspensionVisible = false
      //清空
      this.$refs.suspension.resetFields()
      this.suspensionForm.isopen = 0
    },
    suspension_change_isOpen(isOpen){
      this.suspensionForm.isopen = isOpen
    },
    model_change_isOpen(isOpen){
      this.form.isOpenScope = isOpen
    },
    suspensionOk(){
      this.$refs.suspension.validate(async valid => {
        if (valid) {
          let form = this.suspensionForm;
          let data = {
              funXf: {
                url: form.url,
                icon: form.icon,
                isopen: form.isopen
            }
          }
          const response = await put_function_list(this.suspensionId,data)
          if(response.code === 0){
            this.$message.success("设置成功~")
            //清空
            this.$refs.suspension.resetFields()
            form.isopen = 0
            this.suspensionVisible = false
          }else {
            this.$message.success(response.message)
          }
        } else {
          return false;
        }
      });
    },
    suspensionForm_img() {
      let inputDOM = this.$refs.suspensionForm_img_ref.files[0];
      // 判断图片大小
      if (inputDOM.size < 512000) {
        const isSize = new Promise(function(resolve, reject) {
          let width = 200;
          let height = 200;
          let _URL = window.URL || window.webkitURL;
          let img = new Image();
          img.onload = function() {
            let valid = img.width === width && img.height === height;
            valid ? resolve() : reject();
          };
          img.src = _URL.createObjectURL(inputDOM);
        }).then(async () => {
              const image =await update(inputDOM)
              if (image.code === 0) {
                this.suspensionForm.icon = image.data.url
                this.$message.success("上传成功")
              } else {
                this.$message.warning("上传失败")
              }
              document.getElementById('suspensionForm_imgId').value = null;
              // return inputDOM;
            },
            () => {
              this.$message.error("上传的图片宽高必须是200*200!");
              // return Promise.reject();
            }
        );
      } else {
        this.$message.warning("请上传小于500kb的图片！")
      }
    },
    //获取公司人员列表
    async getPerList(departmentById) {
      //销售部 8994250
      const response = await getCommunictionList(1,9999,1,'',departmentById)
      if(response.code === 0){
        this.PerList = response.data.rows
      }
    },
    //获取关闭或开启的直播类型数据
    async dictionaryList() {
      const response = await getDictionaryList(1)
      if(response.code === 0){
        this.liveBroadcastType = response.data
      }
    },
    // toImport(){
    //   //获取隐藏的input
    //   const inputFile = document.querySelector("input[type='file']");
    //   //触发其点击事件, 弹出文件选择
    //   inputFile.click();
    // },
    async changeFileElsx() {
      let inputDOM = this.$refs.uploadFile_inputFile.files[0];
      let formData = new FormData()
      formData.append('file', inputDOM)
      this.changeFile = inputDOM.name
      this.formData = formData
    },
    excelClose(){
      this.ExcelMember = false;
      this.listPageNo = 1;
      this.listpageSize = 10;
      this.listTel = '';
      this.changeFile = '';
      this.formData = {}
    },
    async importAddFile() {
      const response = await postUploadFile(this.titleList.conventionId,this.formData)
      if (response.code === 200) {
        //上传成功后将value制空防止上传一样名称文件时不触发change
        document.getElementById('uploadFile').value = null;
        //刷新列表
        await this.get_columnsList()
        this.$message.success(response.message)
      } else {
        this.$message.warning(response.message)
      }
    },
    async DownloadTemplate() {
      let data = {
        ExelName:'人员名单模板',
      }
      await getImportExcelMemberTemplate(data)
    },
    inquireBtn(){
      this.listPageNo = 1
      this.listpageSize = 10
      this.get_columnsList()
    },
    templateChange(pagNo,pageSize){
      this.listPageNo = pagNo
      this.listpageSize = pageSize
      this.get_columnsList()
    },
    templateChangeSize(current, size){
      this.listPageNo = current
      this.listpageSize = size
      this.get_columnsList()

    },
    async get_columnsList() {
      let data = {
        page_no:this.listPageNo,
        page_size:this.listpageSize,
        conventionId:this.titleList.conventionId,
        telephone:this.listTel
      }
      const response = await get_credit_registration_list(data)
      if (response.code === 0) {
        this.dataLists = response.data
        this.listCount = response.count
      } else {
        this.$message.warning(response.message)
      }
    },
    async confirmDelList(id) {
      const response = await delMember(id)
      if (response.code === 200) {
         //刷新列表
        await this.get_columnsList()
        this.$message.success("操作成功")
      } else {
        this.$message.warning("出错，请联系管理员", response.message)
      }
    },
    addPerson(){ //人员名单新增
      this.addPersonDisabled = true
      this.addPersonVisile = true
    },
    addPersonClose(){ //人员名单新增-关闭对话框
      this.addPersonVisile = false
      // 切换身份时清空数据
      this.personnelListForm = {
        type:"1001",
        province:undefined,
        hospitalName:undefined,
        school:undefined,
        certStatus:"UNCERTIFIED",
        registerChannel:"后台添加",
      }
      this.hospitalNameList = [];
      this.schoolListData = [];
      this.hosNamePageNo = 1;
      this.schoolNamePageNo = 1;
      this.hosName = '';
      this.schoolName = '';
    },
    addPersonOnSubmit(){  ////人员名单新增-确认对话框
      this.$refs.PersonnelListRef.validate(async valid => {
        if (valid) {
          let form = this.personnelListForm
          if(form.id){
            let data = {
              liveMeetingId:this.live_metting_id
            }
            const response = await insCreditRecord(form.id,this.conventionId,data)
            if(response.code === 200){
              this.$message.success("添加成功~")
              //  关闭对话框并刷新列表
              await this.get_columnsList()
              this.addPersonClose()
            }else {
              this.$message.warning(response.message)
            }
          }else {
            this.$message.warning("该手机号未注册，请先注册~")
          }
        } else {
          return false;
        }
      });
    },
    onSearch(value){
      if(value.length === 11){
        this.echo(value)
      }else {
        this.$message.warning("请规范输入手机号~")
      }
    },
    selectNumberType(value) {
      this.personnelListForm.type = value
      this.$set(this.personnelListForm,'jobTitle',undefined)
      //其他医务从业者--医务从业者不需要选择省份城市
      if (value !== '1006' || value !== '1007') {
        this.getJobTitleList(value)
      }
      if(this.personnelListForm.province){
        this.provinceChange(this.personnelListForm.province)
      }

      // 切换身份时清空数据
      // this.personnelListForm = {
      //   type:value,
      //   province:undefined,
      // hospitalName:undefined,
      //   certStatus:"UNCERTIFIED",
      //   registerChannel:"后台添加",
      // }
    },
    //获取职称列表
    async getJobTitleList(identify,title) {
      //type:identify,pageNum,title
      const response = await getTitle(identify,1,title)
      if (response.code === 0) {
        this.jobTitleList = response.data.row
      }
    },
    //获取省份城市医院
    getPCH(){
      let province = []
      let hospitalName = []
      this.City.forEach((item,index)=>{
        let city = []
        province.push({
          value:item.name,
          label:item.name,
          children:city
        })
        item.cityList.forEach((list)=>{
          city.push({
            value:list.name,
            label:list.name,
            loading:false,
          })
        })
        province[index].children = city
      })
      this.provincePtions = province
    },
    provinceChange(value) {
      this.hosNamePageNo = 1
      this.schoolNamePageNo = 1
      //更改省份城市后清空医院或者学校
      let form = this.personnelListForm
      if(!this.addPersonDisabled){ //当表单在能编辑状态下，选择省份后清空医院或者学校
        form.hospitalName = undefined
        this.hospitalNameList = []
        this.schoolListData = []
        form.school = undefined
      }
      if(form.type === '1001' || form.type === '1002' || form.type === '1003' || form.type === '1004'){
        let data = {
          province:value[0],
          city:value[1],
          pagenum:this.hosNamePageNo,
          name:this.hosName
        }
        this.getHosNameList(data)
      }else if(form.type === '1005'){
        let data = {
          province:value[0],
          city:value[1],
          pagenum:this.schoolNamePageNo,
          name:this.schoolName
        }
        this.getschoolList(data)
      }
    },
    //获取医院列表
    async getHosNameList(data) {
      const response = await getHospital(data)
      if(response.code === 0){
        let list  = response.data.rows
        for (let i=0;i<list.length;i++){
          this.hospitalNameList.push({
            id: list[i].id,
            name: list[i].name,
          })
        }
      }
    },
    //滚动条监听
    popupScrollHos(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.hosNamePageNo++
        let value = this.personnelListForm.province
        let data = {
          province:value[0],
          city:value[1],
          pagenum:this.hosNamePageNo,
          name:this.hosName
        }
        this.getHosNameList(data)
      }
    },
    //鼠标搜索
    selectHos(value){
      //首先清空数组，否则会数据重复
      this.hospitalNameList = []
      this.hosName = value
      this.hosNamePageNo = 1
      let values = this.personnelListForm.province
      let data = {
        province:values[0],
        city:values[1],
        pagenum:this.hosNamePageNo,
        name:value
      }
      this.getHosNameList(data)
    },
    //获取学院列表
    async getschoolList(data) {
      const response = await getSchool(data)
      if(response.code === 0){
        let list  = response.data.rows
        for (let i=0;i<list.length;i++){
          this.schoolListData.push({
            id: list[i].id,
            name: list[i].name,
          })
        }
      }
    },
    //滚动条监听
    popupScrollSchool(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.schoolNamePageNo++
        let value = this.personnelListForm.province
        let data = {
          province:value[0],
          city:value[1],
          pagenum:this.schoolNamePageNo,
          name:this.schoolName
        }
        this.getschoolList(data)
      }
    },
    //鼠标搜索
    selectSchool(value){
      //首先清空数组，否则会数据重复
      this.schoolListData = []
      this.schoolName = value
      this.schoolNamePageNo = 1
      let values = this.personnelListForm.province
      let data = {
        province:values[0],
        city:values[1],
        pagenum:this.schoolNamePageNo,
        name:value
      }
      this.getschoolList(data)
    },
    //获取科室列表
    async getHospitalOfficesList() {
      const response = await getLoginDepartment()
      if(response.code === 0){
        let oneOff = []
        let list = response.data.wySy
        list.forEach((item,index)=>{
          let twoOff = []
          oneOff.push({
            value:item.title,
            label:item.title,
            children:twoOff
          })
          item.zoffices.forEach((lists)=>{
            twoOff.push({
              value:lists.title,
              label:lists.title,
            })
          })
          oneOff[index].children = twoOff
        })
        this.hospitalOfficesList = oneOff
      }
    },
    async echo(value) {
      let data = {
        tel: value,
      }
      const response = await getMemberList(data)
      if (response.code === 0) {
        if (response.count) {
          //  获取用户信息并赋值
          let perDate = response.data[0]
          // 设置数据
          perDate.province = perDate.province?[perDate.province,perDate.city]:[]
          perDate.hospitalOffices = perDate.hospitalOffices?perDate.hospitalOffices.split('-'):null
          if(perDate.name !== '未完善'){
            document.getElementById('name').style.color = ''
          }else {
            document.getElementById('name').style.color = 'red'
          }
          this.personnelListForm = perDate
          // console.log( this.personnelListForm )
        } else {
          this.$message.warning("该用户不存在请添加~")
          this.personnelListForm = {
            type:'1006',
            tel:this.personnelListForm.tel,
            province:undefined,
            hospitalName:undefined,
            school:undefined,
            certStatus:"UNCERTIFIED",
            registerChannel:"后台添加",
            name:'未完善'
          }
          document.getElementById('name').style.color = "red"
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    editPerBtn(){
      this.addPersonDisabled = false
      // document.getElementById('name').style.color = "black"
      if(this.personnelListForm.province){
        // this.provinceChange(this.personnelListForm.province)

        //更改省份城市后清空医院或者学校
        let form = this.personnelListForm
        if(form.type === '1001' || form.type === '1002' || form.type === '1003' || form.type === '1004'){
          let data = {
            province:form.province[0],
            city:form.province[1],
            pagenum:this.hosNamePageNo,
            name:this.hosName
          }
          this.getHosNameList(data)
        }else if(form.type === '1005'){
          let data = {
            province:form.province[0],
            city:form.province[1]
          }
          this.getschoolList(data)
        }
      }
    },
    nameChange(e){
      if(e.target.value !== '未完善'){
        document.getElementById('name').style.color = ''
      }else {
        document.getElementById('name').style.color = 'red'
      }
    },
    async saveEditPer() {
      this.$refs.PersonnelListRef.validate(async valid => {
        if (valid) {
          let form = this.personnelListForm
          let data = {};
          switch (form.type) {
            case '1001': {
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,

                "province": form.province ? form.province[0] : null,
                "city": form.province ? form.province[1] : null,

                "hospitalName": form.hospitalName,
                "hospitalOffices": form.hospitalOffices ? form.hospitalOffices[0] + '-' + form.hospitalOffices[1] : null,
                "jobTitle": form.jobTitle,

                // "doctorNum": form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl": '',
              }
              break;
            }
            case '1002': {
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "hospitalName": form.hospitalName,
                "hospitalOffices": form.hospitalOffices ? form.hospitalOffices[0] + '-' + form.hospitalOffices[1] : null,
                "jobTitle": form.jobTitle,

                "province": form.province ? form.province[0] : null,
                "city": form.province ? form.province[1] : null,

                // "doctorNum": form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl": '',
              }
              break;
            }
            case '1003': {
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "hospitalName": form.hospitalName,
                "hospitalOffices": form.hospitalOffices ? form.hospitalOffices[0] + '-' + form.hospitalOffices[1] : null,
                "jobTitle": form.jobTitle,

                "province": form.province ? form.province[0] : null,
                "city": form.province ? form.province[1] : null,

                // "doctorNum": form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl": '',
              }
              break;
            }
            case '1004': {
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "hospitalName": form.hospitalName,
                "hospitalOffices": form.hospitalOffices ? form.hospitalOffices[0] + '-' + form.hospitalOffices[1] : null,
                "jobTitle": form.jobTitle,

                "province": form.province ? form.province[0] : null,
                "city": form.province ? form.province[1] : null,

                // "doctorNum": form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl": '',
              }
              break;
            }
            case '1005': {
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "school": form.school,
                "education": form.education,
                "specialty": form.specialty,

                "province": form.province ? form.province[0] : null,
                "city": form.province ? form.province[1] : null,

                // "doctorNum": form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl": '',
              }
              break;
            }
            case '1006': {
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "workUnit": form.workUnit,
                "post": form.post,

                "province": form.province ? form.province[0] : null,
                "city": form.province ? form.province[1] : null,

                // "doctorNum": form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl": '',
              }
              break;
            }
            case '1007': {
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "workUnit": form.workUnit,
                "post": form.post,

                "province": form.province ? form.province[0] : null,
                "city": form.province ? form.province[1] : null,

                // "doctorNum": form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                "birthday": form.birthday,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl": '',
              }
              break;
            }
            default: {
              break;
            }
          }
          // 判断form中包不包含id
          if (form.id) {
            // console.log('修改')
            await this.putList(form.id, data)
          } else {
            // console.log('新增')
            await this.postList(data)
            //新增成功后再获取用户信息，并赋值
            await this.echo(data.tel)
          }
          this.addPersonDisabled = true
        } else {
          return false;
        }
      });
    },
    //  新增注册会员信息
    async postList(data) {
      const response = await postMember(data)
      if(response.code === 0){
        this.$message.success("新增成功~")
      }else {
        this.$message.warning(response.message)
      }
    },
    //  修改注册会员信息
    async putList(id,data) {
      const response = await putMember(id,data)
      if(response.code === 0){
        this.$message.success("修改成功~")
      }else {
        this.$message.warning(response.message)
      }
    },
    async uploadDesignSourceFile(record){
      await this.getListImg(record.id);
      this.streamId = record.id;
      await this.dictionarys();
      await this.getFileList(record.id);
      this.designSourceFileVisible = true;
    },
    addExpertGroup(row){
      this.streamId = row.id; //直播间id
      this.getLiveStreamExpertsList(row.id);
      this.visibleExpertGroup = true;
    },
    expertGroupOk(){
      console.log(this.expertList)
      let data = {
        experts:this.expertList,
      }
      this.putdLiveStreamExperts(this.streamId,data)
    },
    //添加专家组
    async putdLiveStreamExperts(id,data) {
      const response = await updLiveStreamExperts(id,data)
      if (response.code === 0) {
        this.$message.success("添加成功~");
        this.expertGroupNo();
      }
    },

    //获取专家组
    async getLiveStreamExpertsList(id) {
      const response = await getLiveStreamExperts(id)
      if (response.code === 0) {
        let list = [];
        for (let i=0;i<response.data.length;i++){
          list.push(response.data[i].id)
        }
        this.expertList = list;
        console.log('专家列表',list);
      }
    },
    expertGroupNo(){
      this.expertList = [];
      this.expertName = '';
      this.expertPageNum = 1;
      this.visibleExpertGroup = false;
    },

    // 专家滚动条监听
    popupScroll_questionCode(e) {
      const { target } = e;
      const scrollHeight = target.scrollHeight - target.scrollTop; //滑动总高度
      const clientHeight = target.clientHeight; //滑动最低高度
      if (scrollHeight < clientHeight + 2) {
        this.expertName = ''
        this.expertPageNum++;
        this.getLogData();
      }
    },

    // 专家框的搜索
    select_hospital(value) {
      this.ExpertSelectList = []
      this.expertName = value;
      this.expertPageNum = ''
      this.getLogData();
    },
    //专家框
    async getLogData(){
      const data={
        pageNum:this.expertPageNum,
        name:this.expertName,
      }
      const response = await  getExpertList(data)
      if (response.code === 0) {
        let list = response.data.rows;
        for (let i = 0; i < list.length; i++) {
          this.ExpertSelectList.push({
            id: list[i].id,
            name: list[i].name,
          });
        }
      } else {
        this.$message.error("获取错误，请联系管理员~" + response.message);
      }
    },


    //获取单个直播间
    async getListImg(id) {
      const response = await getOneLiveList(id)
      if (response.code === 0) {
        //保存海报和kv图
        this.imgList = {
          descImageUrl:response.data.descImageUrl,
          detailCoverUrl:response.data.coverUrl,
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    //获取直播间源文件
    async getFileList(id) {
      this.fileSpin = true;
      const response = await getFileByStreamId(id)
      if (response.code === 0) {
        this.fileListImg = [];
        for (let i=0;i<response.data.length;i++){
          //获取初始上传的并且是发布到模板的设计源文件数据
            if(response.data[i].isTemplate){
              this.fileListImg.push(response.data[i]);
            }
          //数据字典列表 - 上传类型列表
          this.designSourceFileForm.uploadType.push(response.data[i].designId);
          //数据字典回显
          let list = {
            switch:response.data[i].isTemplate ? true:false,
            imgUrl:response.data[i].previewImg,
            imgUrlPSD:response.data[i].fileUrl,
            // imgName:response.data[i].fileUrl,
            type:response.data[i].designId
          }
          this.designSourceFileForm.fileList.push(list)
        }
      }
      this.fileSpin = false;
    },
    //获取设计字典列表
    async dictionarys() {
      const response = await dictionary(1)
      if(response.code === 0){
        this.uploadTypeList = response.data
      }
    },
    designSourceFileClose(){
      this.designSourceFileVisible = false;
      this.designSourceFileForm = {
        uploadType:[],
            // switch:false,
            // 设计源文件
            fileList:[]
      }
    },
    //传id,返回设计源文件类型名称
    returnName(type){
      for (let i=0;i<this.uploadTypeList.length;i++){
        if(this.uploadTypeList[i].id === type){
          return this.uploadTypeList[i].name
        }
      }
    },
    async designSourceFileSubmit() {
      let isSub = true;
      let list = this.designSourceFileForm.fileList;
      let data = {};
      for (let i = 0; i < list.length; i++) {
        data = {
          "liveMeetingId": this.live_metting_id,
          "streamId": this.streamId,
          "designId": list[i].type,
          "fileUrl": list[i].imgUrlPSD,
          "previewImg": list[i].imgUrl,
          "isTemplate": list[i].switch ? 1 : 0   //关闭或1开启
        }
        for (var key in data) {
          if (data[key] === '') {
            isSub = false;
            return this.$message.warning('第'+(i+1)+'个源文件中数据有空，请将数据补充完整!')
          }
        }
      }
      //循环新增数据
      if (isSub) {
        this.fileSpin = true;
        //现有
        let newList = [];
        for (let j = 0; j < list.length; j++) {
          data = {
            "liveMeetingId": this.live_metting_id,
            "streamId": this.streamId,
            "designId": list[j].type,
            "fileUrl": list[j].imgUrlPSD,
            "previewImg": list[j].imgUrl,
            "isTemplate": list[j].switch ? 1 : 0   //关闭或1开启
          }
          newList.push(list[j].type)
          await upLoadSourceFile(data);
        }
        //判断初始 在 现在选择的源文件中比较，缺少了那些源文件，将模板库中的删掉
        let oldList = this.fileListImg;

        for(let k=0;k<oldList.length;k++){

          if(newList.indexOf(oldList[k].designId)===-1){
            //不存在，则删掉设计源文件以及模板库中的数据
            await delLoadSourceFile(oldList[k].id)
          }

        }
      }
      this.$message.success("操作成功~");
      this.designSourceFileClose();
      this.fileSpin = false;
    },
    diff(arr1, arr2) { //得到两个数组的差异
      var newArr = [];
      arr1 = Array.from(new Set(arr1)); // 去重
      arr2 = Array.from(new Set(arr2)); // 去重
      newArr =arr1.concat(arr2);
      return newArr.filter(x=>!(arr1.includes(x) && arr2.includes(x)))
    },
    uploadTypeChange(vals){
      let list = this.designSourceFileForm.fileList;
      // let liveImg = JSON.parse(localStorage.getItem('liveImg'));
      let liveImg = this.imgList;
      let listId = [];
      for (let i=0;i<list.length;i++){
        listId.push(list[i].type)
      }

      let listType = this.diff(listId,vals);

      if(list.length < vals.length){//新增
        let imgUrl = '';
        //判断是否是kv图和海报，若是则自动获取父级图片预览图
        if(this.returnName(listType[0]) === '海报'){
          imgUrl = liveImg.descImageUrl;
        }else if(this.returnName(listType[0]) === 'KV图'){
          imgUrl = liveImg.detailCoverUrl;
        }
        let data = {
              switch:false,
              imgUrl:imgUrl,
              imgUrlPSD:'',
              imgName:'',
              type:listType[0]
            }
        list.push(data)

      }else if(list.length > vals.length){ //减少

        list.forEach((item,index)=>{
          if(item.type === listType[0]){
            list.splice(index,1)
          }
        })

      }
    },
    async sourceFileUp(list,idorRef,indexs) {
      let inputDOM = this.$refs.filesRef[indexs].files[0];
      const image = await update(inputDOM)
      if (image.code === 0) {
        list.imgUrlPSD  = image.data.url
        list.imgName = inputDOM.name
        this.$message.success("上传成功")
      } else {
        this.$message.warning(image.message)
      }
      document.getElementById(idorRef).value = null
    },
    delPSD(files){ //删除上传的psd文件
      files.imgUrlPSD = ''
      files.imgName = ''
    },
    async sourceFileImgUp(list,idorRef,indexs) {
      let inputDOM = this.$refs.filesRefImg[indexs].files[0];
      const image = await update(inputDOM)
      if (image.code === 0) {
        list.imgUrl = image.data.url
        this.$message.success("上传成功")
      } else {
        this.$message.warning(image.message)
      }
      document.getElementById('img'+idorRef).value = null
    },
    designSourceFileForm_isOpen(isOpen,files){
      this.$set(this.designSourceFileForm.fileList,files.switch,isOpen)
    },
    async scoreOpen(id) {
      await this.getLiveRatingList(id);
      this.visibleLive = true;
    },
    add_cancel(){
      this.visibleLive = false;
    },
    // 合并单元格
    rowSpan (key, data) {
      const arr = data
          .reduce((result, item) => {
            if (result.indexOf(item[key]) < 0) {
              result.push(item[key])
            }
            return result
          }, [])
          .reduce((result, keys) => {
            const children = data.filter(item => item[key] === keys)
            result = result.concat(
                children.map((item, index) => ({
                  ...item,
                  [`${key}RowSpan`]: index === 0 ? children.length : 0
                }))
            )
            return result
          }, [])
      return arr
    },
    // 表格合并
    mergeRowCell (data) {
      let tableData = this.rowSpan('largeCategory', data)
      this.dataModel = tableData
    },
    async getLiveRatingList(streamId) {
      const response = await getLiveScoreRecordsByStreamId(streamId)
      if(response.code === 0){
        this.dataModel = response.data
        await this.mergeRowCell(this.dataModel)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.setLive{
  .setLive-title{
    width: 100%;
    height: 60px;
    line-height: 50px;
    display: flex;
    border-bottom: 10px solid #f0f2f5;
    .setLive-title-title{
      font-size: 16px;
      font-weight: bold;
      margin-left: 20px;
      width: 50%;
    }
  }
  .setLive-top{
    display: flex;
    height: 80px;
    border-bottom: 10px solid #f0f2f5;
    .setLive-top-content{
      display: flex;
      height: 80px;
      line-height: 80px;
      width: 30%;
      .setLive-title-address{
        width: 50%;
        font-size: 16px;
        display: flex;
      }
    }
  }
  .setLive-table{
    width: 100%;
    .setLive-table-content{
      width: 98%;
      margin: auto;
      padding-top: 20px;
    }
  }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_deyails{
  border: #DDDDDD 2px dashed;
  width: 240px;
  height: 135px;
  margin-left: 20px;
  text-align: center;
}
.upload_poster{
  border: #DDDDDD 2px dashed;
  width: 160px;
  height: 120px;
  margin-left: 20px;
  text-align: center;

  background-image: url("../../assets/upImg.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;

}.upload_posterH{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: auto;
  //min-height: 120px;
  margin-left: 20px;
  text-align: center;
}
.upload_LiveBroadcast{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  margin-left: 20px;
  text-align: center;
}
.add-file{
  width:80px;
  height:32px;
  float:left;
  position: relative;
}
</style>
