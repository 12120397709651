import{get,put,post,del}from"./base";
import qs from "qs";


/**栏目管理 **/
export function getProgram(pageNum,title){
    return get('/v3/columns',{pageNum,title,pageSize:999})
}
//获取单个数据
export function getProgramId(id){
    return get( '/v3/column/'+ id)
}
//新增栏目
export function postProgram(data){
    return post('/v3/column',qs.stringify(data))
}
//删除栏目
export function delProgram(id){
    return del('/v3/column/' + id)
}
//修改栏目
export function putProgram(id,data){
    return put('/v3/column/'+ id, qs.stringify(data))
}
//栏目上下移动
export function postProgramUp(id){
    return post('/v3/moveup/' +id)
}
export function postProgramDown(id){
    return post('/v3/movedown/' +id)
}


/**注册科室 **/
export function  getLoginDepartment(pageNum,name){
    return get('/v3/offices',{pageNum,name})
}
//新增
export function postLoginDepartment(data){
    return post('/v3/offices',data)
}
//修改
export function putLoginDepartment(id,data){
    return put('/v3/offices/' + id,data)
}
//删除科室
export function delLoginDepartment(id){
    return del('/v3/offices/' + id)
}

export function upOfDownOffices(id,type){
    return put('/v3/upOfDownOffices/'+id+'/'+type)
}

/**注册职称管理 **/

export function  getTitle(identify,pageNum,title){
    return get('/v3/jobtitles',{identify,pageNum,title})
}
export function  getTitle2(identify){
    return get('/v3/jobtitles',{identify,"pageNum":1,"pageSize":999})
}
//新增职称
export function postTitle(data){
    return post('/v3/jobtitle',data)
}
//修改
export function putTitle(id,data){
    return put('/v3/jobtitle/' + id,data)
}
//删除
export function delTitle(id){
    return del('/v3/jobtitle/' + id)
}
