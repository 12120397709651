<template>
<div>

  <a-drawer
      :visible="visible"
      :width="1300"
      :body-style="{ paddingBottom: '80px' }"
      title="名单人员观看时长"
      @close="ClosMain">
    <div style="width: 98%;text-align: right">
      <a-button
          @click="exportBtn"
          style="margin-left: 10px">
        <a-icon type="file-excel" style="color: #1ea134;font-size: 16px" />
        批量导出
      </a-button>
    </div>
    <a-table
        style="margin-top: 10px;width: 98%;"
        :columns="columns_People_watch"
        :rowKey='record=>record.id'
        :pagination="false"
        :data-source="data_People_watch">
    </a-table>
    <div style="width: 100%;height: 50px;">
      <div style="float: right;margin-right: 20px;margin-top: 15px">
        <a-pagination
            :current="page_no"
            @change="listChange_People_watch"
            :total="tableCount_People_watch" />
      </div>
    </div>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
    >
      <a-button :style="{ marginRight: '8px' }" @click="ClosMain">
        取消
      </a-button>
    </div>
  </a-drawer>
</div>
</template>

<script>
import {getLiveWatchRecord, getCreditLiveWatchRecord, getCreditLiveWatchRecordExport} from "@/service/liveMeet_api";

const columns_People_watch = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
  },{
    title: '性别',
    dataIndex: 'sex',
    key: 'sex',
  },{
    title: '生日',
    dataIndex: 'birthday',
    key: 'birthday',
  },
  {
    title: '医院',
    dataIndex: 'hospital',
    key: 'hospital',
  },
  {
    title: '职称',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
  },
  {
    title: '是否来自基层',
    dataIndex: 'isBasicLevel',
    key: 'isBasicLevel',
    ellipsis: true,
  },
  {
    title: '省份',
    dataIndex: 'province',
    key: 'province',
    ellipsis: true,
  },
  {
    title: '身份',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true,
  },
  {
    title: '手机号',
    dataIndex: 'telephone',
    key: 'telephone',
    ellipsis: true,
  },
  {
    title: '观看时长(分钟)',
    dataIndex: 'duration',
    key: 'duration',
    ellipsis: true,
  },
];
export default {
name: "ViewingDuration",
  props:{
    visible:{
      type:Boolean,
      required: true,
    },
    conventionId:{
      type:String,
      required: true,
    }
  },
  data(){
  return{
    columns_People_watch,
    data_People_watch:[],
    tableCount_People_watch:0,
    page_no:1,
    page_size:10,
    tel:'',
    name:'',
  }
  },
  mounted() {
  this.getPeopleWatch();
  },
  methods:{
    ClosMain(){
      let isOpen = false;
      this.$emit('ClosMain',isOpen)
    },
    //人员观看总时长
    async getPeopleWatch() {
      let data = {
        tel:this.tel,
        name:this.name,
        page:this.page_no,
        pageSize:this.page_size
      }
      let response = await getCreditLiveWatchRecord(this.conventionId,data)
      if(response.code === 0){
        this.data_People_watch = response.data;
        this.tableCount_People_watch = response.count;
      }else {
        this.$message.warning("失败，请联系管理员",response.message);
      }
    },
    listChange_People_watch(page_no,page_size){
      this.page_no = page_no;
      this.page_size = page_size;
      this.getPeopleWatch();
    },

    async exportBtn() {
      let data1 = {
        tel: this.tel,
        name: this.name,
      }
      //原始观看记录
      data1.ExelName = '名单人员观看时长'
      const response = await getCreditLiveWatchRecordExport(this.conventionId,data1)
    }
  }
}
</script>

<style scoped>

</style>
