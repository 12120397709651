import {get,post,put,del} from "./base";
// 学校管理


//获取学校信息
export function getSchool(data){
    return get('/v3/schools',data)
}
export function getSchoolId(id){
    return get('/v3/school/'+id)
}
//新增学校
export function postSchoolAdd(data){
    return post('/v3/school',data)
}
//修改
export function putSchoolEdit(id,data){
    return put('/v3/school/'+id,data)
}
//删除
export function delSchool(id){
    return del('/v3/school/' +id)
}