//返回性别
export function sexDate(){
    let sex = [
        {id:1, sex:'男', value:1,},
        {id:2, sex:'女', value:2,},
        {id:3, sex:'未知',value:0,}
        ]
    return sex
}
//返回学历
export function educationList(){
    let education = [
            {
                id:'1',
                education:'初中'
            },
            {
                id:'2',
                education:'高中'
            },
            {
                id:'3',
                education:'专科'
            },
            {
                id:'4',
                education:'本科'
            },
            {
                id:'5',
                education:'研究生'
            },
        ]
    return education
}
//返回直播类型
export function LiveBroadcast(){
    let LiveBroadcasts = [
        {
            id:'1',
            title:'转播'
        },
        {
            id:'2',
            title:'线上直播'
        },
        {
            id:'3',
            title:'线上双语直播'
        },
        {
            id:'4',
            title:'现场直播'
        },
        {
            id:'5',
            title:'现场双语直播'
        },{
            id:'6',
            title:'手术直播'
        },
    ]
    return LiveBroadcasts
}
