import {get,put} from "./base";

/**
 * 获取主管或销售评分列表(直播会议)
 * @param data
 * @returns {Promise<unknown>}
 */
export function getLiveStreamForRecordsZG(data){
    return get('/api/admin/getLiveStreamForRecords',data)
}

/**
 * 获取主管或销售评分列表（医学会议）
 * @param data
 * @returns {Promise<unknown>}
 */
export function getConventionForRecords(data){
    return get('/api/admin/getConventionForRecords',data)
}
/**
 * 获取直播会议下直播评分信息
 * @param streamId
 * @returns {Promise<unknown>}
 */
export function getLiveScoreRecordsByStreamId(streamId){
    return get('/api/admin/getLiveScoreRecordsByStreamId?streamId='+streamId)
}

/**
 * 主管或销售提交评分
 * @param data
 * @returns {Promise<unknown>}
 */
export function updLiveScoreRecords(data){
    return put('/api/admin/updLiveScoreRecords',data)
}

/**
 * 获取非直播类分数统计
 * @param conventionId
 * @returns {Promise<unknown>}
 */
export function getLiveScoreRecordsByConventionId(conventionId){
    return get('/api/admin/getLiveScoreRecordsByConventionId?conventionId='+conventionId)
}

/**
 * 获取直播类分数统计
 * @param data   date 日期  conventionId 大会id
 * @returns {Promise<unknown>}
 */
export function getRecordsByIdAndDate(data){
    return get('/api/admin/getRecordsByIdAndDate',data)
}

/**
 * 获取直播分数统计日期
 * @param conventionId
 * @returns {Promise<unknown>}
 */
export function getDateByConventionId(conventionId){
    return get('/api/admin/getDateByConventionId?conventionId='+conventionId)
}

/**
 * 主管或销售一键清空
 * @param data type 主管传ZG 销售传XS  reason 理由
 * @returns {Promise<unknown>}
 */
export function oneClickClear(type,reason,data){
    return put('/api/admin/oneClickClear?type='+type+'&reason='+reason,data)
}
export function clickClear(type,reason,conventionId){
    return put('/api/admin/oneClickClearForConventionId?type='+type+'&reason='+reason+'&conventionId='+conventionId)
}
/**
 * 获取直播间源文件
 * @param streamId
 * @returns {Promise<unknown>}
 */
export function getFileByStreamId(streamId){
    return get('/api/admin/getFileByStreamId/'+streamId)
}
